<template>
  <section>
    <section v-if="shouldShowPanel" class="document-binder-panel">
      <v-list dense>
        <template v-if="documentCollectionItems.quickAccessItems.length > 0">
          <v-divider />
          <v-list-group :value="isOpenQuickAccess">
            <template #activator>
              <v-list-item-title>クイックアクセス</v-list-item-title>
            </template>

            <v-list-item
              v-for="item in documentCollectionItems.quickAccessItems"
              :key="item.id"
              link
              @click="$emit('click-bookmark', item)"
            >
              <v-list-item-content>
                <div>
                  <template v-if="item.viewType !== 'document'">
                    {{ item.headingText }}
                  </template>
                  <span v-if="item.viewType === 'pdf'" class="type-label -pdf">PDF</span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-if="documentCollectionItems.binderItems.length > 0">
          <template v-for="(item, index) in documentCollectionItems.binderItems">
            <v-divider :key="index" />
            <v-list-group :key="item.binder.id">
              <template #activator>
                <v-list-item-title>{{ item.binder.name }}</v-list-item-title>
              </template>

              <template v-if="item.bookmarks.length > 0">
                <v-subheader> <v-icon size="14" left>mdi-bookmark</v-icon>ページ/セクション</v-subheader>
                <v-list-item
                  v-for="bookmark in item.bookmarks"
                  :key="bookmark.id"
                  link
                  @click="$emit('click-bookmark', bookmark)"
                >
                  <v-list-item-content>
                    <div>
                      {{ bookmark.sectionTitle }}
                      <span v-if="bookmark.viewType === 'pdf'" class="type-label -pdf">PDF</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-if="item.highlights.length > 0">
                <v-subheader><v-icon size="14" left>mdi-marker</v-icon>テキスト</v-subheader>
                <v-list-item
                  v-for="highlight in item.highlights"
                  :key="highlight.id"
                  link
                  @click="$emit('click-highlight', highlight)"
                >
                  <v-list-item-content v-text="highlight.sectionTitle"></v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item class="binder-link">
                <nuxt-link :to="`/binder/${item.binder.id}`">「{{ item.binder.name }}」を開く</nuxt-link>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <v-divider />
      </v-list>
    </section>
    <p v-else class="binder-description" v-text="textNoItem" />
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { DocumentCollectionItems } from '../toc-panel';

@Component
export default class DocumentBinderPanel extends Vue {
  @Prop() documentCollectionItems!: DocumentCollectionItems;

  get shouldShowPanel(): boolean {
    return (
      this.documentCollectionItems.quickAccessItems.length > 0 || this.documentCollectionItems.binderItems.length > 0
    );
  }

  /** クイックアクセスを最初から開いておくかのフラグ。バインダー機能が無効の場合は一つだけになるので開いておく */
  get isOpenQuickAccess(): boolean {
    return !this.$auth.permissions.binder;
  }

  get textNoItem(): string {
    return this.$auth.permissions.binder
      ? 'この文献はどのバインダーにも追加されていません'
      : 'この文献はクイックアクセスに追加されていません';
  }
}
</script>

<style lang="scss" scoped>
.document-binder-panel {
  background: white;

  .binder-link {
    height: auto;
    display: block;
    margin-top: 20px;
    padding: 10px 16px;
    font-size: 14px;
    text-align: right;
    background: #f6f6f6;

    > a {
      text-decoration: none;
      color: $primary;
    }
  }

  // Vuetify のスタイルを上書き
  > .v-list {
    padding: 0;
  }

  .v-list-item__title {
    font-size: 16px;
  }

  .v-list-item {
    font-size: 14px;
  }

  .v-subheader {
    height: auto;
    margin: 0 16px 6px;
    padding: 6px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .v-list-item + .v-subheader {
    margin-top: 10px;
  }
  // Vuetify のスタイルを上書き
}

.binder-description {
  background: white;
  margin: 12px;
  padding: 12px 8px;
  border: solid 1px #e6e6e6;
  font-size: 14px;
  line-height: 1.6;
}

.type-label.-pdf {
  display: inline-block;
  margin-left: 4px;
  padding: 1px 4px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #efefef;
  color: #999;
}
</style>
