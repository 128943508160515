<template>
  <div class="toc-v2-glance">
    <ol>
      <li v-for="item in tocItems" :key="item.key">
        <div class="toc-v2-glance-hit-counter" :style="{ left: item.relativeDepth * 2.5 + 'em' }">
          <div
            v-if="item.sectionHit && item.sectionHit.sectionHits"
            :class="{ '-hit-all': item.sectionHit.containsAllKeywordsMatch }"
            v-text="item.sectionHit.sectionHits"
          />
        </div>
        <a
          :href="hrefBase + item.key"
          :style="{ paddingLeft: item.relativeDepth * 2.5 + 'em' }"
          :class="{ '-no-hit': isFindStateActivated && item.sectionHit && !item.sectionHit.sectionHits }"
          @click.stop.prevent="visitSection(item.key)"
        >
          {{ item.label }}
        </a>
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import { State as MyState, FindState } from '@/store';
import { tocReferenceKey } from '@/utils/tocUtils';
import { TocInteractive } from '@/types/Viewer';

@Component({ name: 'toc-v2-glance' })
export default class TocV2GlanceComponent extends Vue {
  @Prop() docId!: string;
  @Prop() nodeInfo!: TocInteractive;

  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;
  @State((state: MyState) => state.document.find) findState!: FindState;

  /** 文書内検索の結果を表示中かどうか */
  get isFindStateActivated(): boolean {
    return this.findState.hit.documentHits != null;
  }

  get tocItems(): (TocInteractive & { relativeDepth: number })[] {
    const tocItems = [];

    const stack = [...this.nodeInfo.children].reverse();

    while (stack.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const key = stack.pop()!;
      const tocItem = this.toc[tocReferenceKey(this.docId, key)];
      tocItems.push(tocItem);
      stack.push(...[...tocItem.children].reverse());
    }

    const minLogicalDepth = Math.min(...tocItems.map(({ logicalDepth }) => logicalDepth));

    return tocItems.map((item) => ({ ...item, relativeDepth: item.logicalDepth - minLogicalDepth }));
  }

  get hrefBase() {
    return this.$router.resolve({
      path: '/document/' + this.docId,
      query: this.$route.query,
      hash: 'key=',
    }).href;
  }

  visitSection(key: number): void {
    this.$emit('visitSection', { docId: this.docId, key });
    this.$telemetry.sendClickTelemetry({ button: 'toc-v2__visit-from-glance', id: this.docId, key }, this.$route);
  }
}
</script>

<style lang="scss">
.toc-v2-glance {
  background: #f8f8f8;
  font-size: 0.83em;
  overflow: auto;

  ol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 70vh;
    width: auto;
    margin: 12px;
    padding: 0;
    gap: 3px 1em;
  }

  li {
    display: flex;
    list-style: none;
    padding-right: 24px;
    gap: 0.3em;
  }

  .v-application & a {
    flex: auto;
    text-decoration: none;
    color: #333;

    &.-no-hit {
      color: #ccc;
    }

    &:hover {
      background-color: scale-color($toc-background-reading, $lightness: 65%);
      color: #000;
    }
  }
}

.toc-v2-glance-hit-counter {
  position: relative;
  width: 1em;
  height: 1em;
  text-align: center;

  > div {
    position: relative;
    width: 1em;
    height: 1em;
    font-size: 0.7em;
    border: 1px solid #ccc;
    border-radius: 2em;
    background: white;
    line-height: 1em;

    &.-hit-all {
      margin-right: -4px;
      margin-left: -4px;
      width: calc(1em + 8px);
      height: calc(1em + 8px);
      border-color: #22c;
      color: #22c;
      line-height: calc(1em + 8px - 1px);

      &::after {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        display: block;
        border: 1px solid #88c;
        border-radius: 2em;
        content: '';
      }
    }
  }
}
</style>
