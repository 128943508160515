<template>
  <li
    v-if="nodeInfo"
    :data-toc-node="referenceKey"
    class="toc-v2-node"
    :class="{ active: nodeInfo.active, focused: isFocused }"
  >
    <!-- z-index: $layer-document-toc-content-hover -->
    <v-menu
      offset-x
      open-on-hover
      :close-on-content-click="false"
      :disabled="!showGlance"
      open-delay="350"
      z-index="1101"
    >
      <template #activator="{ on, attrs }">
        <div class="toc-container" v-bind="attrs" v-on="on" @click.stop.prevent="visitSection">
          <hit-counter-v2 v-bind="{ nodeInfo, showAsHavingChildren, open }" />
          <div
            class="toc-content"
            :class="{
              '-less-important': variant === 'kommentar' && nodeInfo.depth !== KommentarArticleDepth,
              '-no-hit': isFindStateActivated && !nodeInfo.sectionHit,
            }"
          >
            <a :href="href" class="text" :style="{ paddingLeft }" @click.stop.prevent="visitSection" v-text="text" />
            <wklr-icon-button
              v-if="showAsHavingChildren"
              type="chevron"
              :dense="true"
              :direction="open ? 'down' : 'right'"
              :title="open ? '折りたたむ' : '展開する'"
              @click.stop="toggle"
            />
            <v-icon v-else-if="showGlance" style="opacity: 0.7">mdi-menu-right</v-icon>
          </div>
        </div>
      </template>
      <toc-glance v-if="showGlance" v-bind="{ docId, nodeInfo }" @visitSection="$emit('visitSection', $event)" />
    </v-menu>

    <ol v-if="showAsHavingChildren && open">
      <toc-v2-node
        v-for="tocKey in nodeInfo.children"
        :key="tocKey"
        v-bind="{
          ...$props,
          tocKey,
        }"
        @visitSection="$emit('visitSection', $event)"
      />
    </ol>
  </li>
</template>

<script lang="ts">
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import * as Constants from '@/constants';
import { Viewer, TocInteractive } from '@/types/Viewer';
import { State as MyState, FindState } from '@/store';
import { Nullable } from '@/types/nullable';
import HitCounterV2 from '@/components/toc-panel/hit-counter-v2.vue';
import TocGlance from '@/components/toc-panel/toc-v2-glance.vue';
import WklrIconButton from '@/components/shared/wklr-icon-btn.vue';
import { DocRecordExtended, tocReferenceKey } from '@/utils/tocUtils';

@Component({
  name: 'toc-v2-node',
  components: { HitCounterV2, TocGlance, WklrIconButton },
})
export default class TocV2NodeComponent extends Vue {
  @Prop() docId!: string;
  @Prop() tocKey!: number;
  @Prop() hidePageSeq!: boolean;
  @Prop() currentRecord!: DocRecordExtended;
  @Prop() activeViewer!: Viewer;
  /** 現在閲覧中の節のkey（特にない場合や閲覧中の文献がこのnodeを含む巻でない場合はnull） */
  @Prop() focusedNode!: Nullable<number>;

  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;
  @State((state: MyState) => state.document.find) findState!: FindState;

  readonly KommentarArticleDepth = Constants.Document.Toc.KommentarArticleDepth;

  get referenceKey(): string {
    return tocReferenceKey(this.docId, this.tocKey);
  }

  get nodeInfo(): TocInteractive {
    return this.toc[this.referenceKey];
  }

  get open(): boolean {
    if (this.nodeInfo.isLeaf) {
      return true;
    }
    if (this.nodeInfo.open !== null) {
      return this.nodeInfo.open;
    }

    if (this.nodeInfo.active) return true;
    // // FIXME: accumulatedHitKeywordsCount をサーバ側で実装してそれに置き換え、子要素のレンダリングを止める（ L39 に isOpen の判定を追加する）
    if (this.nodeInfo.sectionHit !== null && this.nodeInfo.sectionHit.hitKeywordsCount >= 2) return true;
    return false;
  }

  /** 文書内検索の結果を表示中かどうか */
  get isFindStateActivated(): boolean {
    return this.findState.hit.documentHits != null;
  }

  get text(): string {
    return this.hidePageSeq ? this.nodeInfo.label : `${this.nodeInfo.label} ${this.nodeInfo.folioLabel}`;
  }

  get href() {
    return this.$router.resolve({
      path: '/document/' + this.docId,
      query: this.$route.query,
      hash: 'key=' + this.tocKey,
    }).href;
  }

  /** 現在閲覧中の文献の種類に応じたバリエーションを指定する */
  get variant(): 'kommentar' | 'default' {
    if (this.currentRecord.isKommentar) {
      return 'kommentar';
    }

    return 'default';
  }

  /** このnodeをTOC上で子要素を持つように表示させるかどうか */
  get showAsHavingChildren(): boolean {
    if (this.nodeInfo.isLeaf) {
      // そもそも子要素がない
      return false;
    }

    if (this.variant === 'kommentar' && this.nodeInfo.depth >= Constants.Document.Toc.KommentarArticleDepth) {
      // コンメンタールでは条レベルの項目より下位は省略する
      return false;
    }

    return true;
  }

  /** 今ちょうど見ている位置に対応するTOC項目かどうか */
  get isFocused(): boolean {
    if (this.focusedNode == null) {
      return false;
    }

    if (this.focusedNode === this.tocKey) {
      return true;
    }

    if (this.variant === 'kommentar' && this.nodeInfo.depth >= Constants.Document.Toc.KommentarArticleDepth) {
      // この場合、今ちょうど見ている位置はTOCでは非表示の細かい項目かもしれない
      // 自分がその祖先でないかチェックする
      for (let cursor = this.focusedNode; cursor !== -1; ) {
        if (cursor === this.tocKey) {
          return true;
        }

        cursor = this.currentRecord.toc?.byKey[cursor].parent ?? -1;
      }
    }

    return false;
  }

  /** 俯瞰ポップアップを表示するかどうか */
  get showGlance(): boolean {
    return !this.nodeInfo.isLeaf && !this.showAsHavingChildren;
  }

  /** style: 論理深さによるインデント */
  get paddingLeft(): string {
    return this.nodeInfo.logicalDepth * 10 + 25 + 5 + 'px';
  }

  visitSection(): void {
    this.$store.commit('toggleTocOpen', { key: this.referenceKey, isOpen: true });
    if (this.activeViewer === 'pdf' && this.nodeInfo.pdfPageScrollTo) {
      this.nodeInfo.pdfPageScrollTo();
    } else {
      this.$emit('visitSection', { docId: this.docId, key: this.tocKey });
    }

    this.$telemetry.sendClickTelemetry(
      { button: 'toc-v2__visit', id: this.docId, key: this.tocKey, currentID: this.currentRecord.id },
      this.$route,
    );
  }

  toggle(): void {
    this.$telemetry.sendClickTelemetry(
      {
        button: this.nodeInfo.open ? 'toc-v2__fold' : 'toc-v2__unfold',
        id: this.docId,
        key: this.tocKey,
        currentID: this.currentRecord.id,
      },
      this.$route,
    );
    this.$store.commit('toggleTocOpen', { key: this.referenceKey, isOpen: !this.nodeInfo.open });
  }
}
</script>

<style lang="scss" scoped>
@import '../viewer/text-content-style/mixin.scss';

.toc-v2-node {
  width: 100%;
  background: white;
  list-style: none;

  > .toc-container {
    display: flex;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px solid $toc-divider-color;
    border-left: 8px solid transparent;
    cursor: pointer;

    > .toc-content {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      padding-right: 4px;
      min-height: 26px; // 文字サイズが小さくてもchevron分は確保しておく
      flex: 1 1 100%;
      color: #333;
      text-indent: -25px; // 逆に2行目以降をインデントする（1行目ではpadding-leftで相殺させる）

      &.-less-important {
        color: #777;

        @include font-set(#{11px * 0.8}, #{13px * 0.8}, #{18px * 0.8}, #{24px * 0.8});
      }

      &.-no-hit {
        color: #999;
      }

      > .text {
        display: block;
        flex: 1 1 auto;
        text-decoration: none;
        color: inherit;
      }
    }

    &:hover {
      background-color: scale-color($toc-background-reading, $lightness: 65%);

      > .toc-content {
        color: #000;
      }
    }
  }

  &.active > .toc-container {
    border-left-color: $toc-background-reading;
    color: #000;
  }

  &.focused > .toc-container {
    background-color: $toc-background-reading;
    color: #000;
  }
}
</style>
