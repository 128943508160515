<template>
  <ol id="toc-root" ref="tocRoot" class="toc-root" :class="`font-size-${fontSize}`">
    <toc-node
      v-for="tocKey in rootNodeKeys"
      ref="rootTocNode"
      :key="tocReferenceKey(record.id, tocKey)"
      :doc-id="record.id"
      :toc-key="tocKey"
      :hide-page-seq="isSeqUnique || isLaw || activeViewer === 'pdf'"
      :active-viewer="activeViewer"
      :focused-node="focusedNode"
      @visitSection="$emit('visitSection', $event)"
    />
  </ol>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import TocNode from '@/components/toc-panel/toc-node.vue';
import { Viewer } from '@/types/Viewer';
import { Nullable } from '@/types/nullable';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { DocRecordExtended, tocReferenceKey } from '@/utils/tocUtils';

@Component({ components: { TocNode } })
export default class TocNodesPanel extends Vue {
  $refs!: {
    rootTocNode: TocNode[];
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  tocReferenceKey = tocReferenceKey;

  @Prop() record!: DocRecordExtended;
  @Prop() activeViewer!: Viewer;
  @Prop() rootNodeKeys!: number[];
  @Prop() inViewSections!: number[];
  @Prop() inViewSectionsWithAncestors!: number[];
  @Prop() focusedNode!: Nullable<number>;
  @Prop() fontSize!: '14' | '18' | '24' | '34';

  get isLaw(): boolean {
    return this.record.type === DocumentTypeEnum.Law;
  }

  get isSeqUnique(): boolean {
    return this.record?.isSeqUnique || false;
  }
}
</script>
