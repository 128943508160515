import { byKeySorter } from './arrayUtil';
import { LookupResult, Toc } from 'wklr-backend-sdk/models';
import { TocInteractive } from '@/types/Viewer';
import { tocReferenceKey, tocReferenceKeyPrefix } from './tocUtils';

/** セクションごとの検索マッチ情報 */
export type SectionHit = {
  /** 対応するセクションの key  */
  key: number;
  /** 含まれる検索マッチの総数 (セクション内のみ) */
  sectionHits: number;
  /** セクション内、および子孫にあたるサブセクションに含まれる検索マッチ数の累計 */
  accumulatedHits: number;
  /** 含まれるキーワードごとの検索マッチ数 (セクション内のみ) */
  sectionHitsByKeyword: { [keyword: string]: number };
  /** ヒットしたキーワード数 (セクション内のみ) */
  hitKeywordsCount: number;
  /** セクション内、および子孫にあたるサブセクションのいずれかで、指定されたキーワードすべてがヒットしたセクションがあるか */
  containsAllKeywordsMatch: boolean;
};

/** コンテントごとの検索マッチ情報 */
type ContentHit = {
  /** 対応するコンテントの series */
  series: number;
  /** コンテントが含まれるセクションの key */
  parent: number;
  /** 含まれる検索マッチの総数 */
  total: number;
  // キーワード情報は現時点では不要なので生成しない。必要なら実装すること
};

/** 各検索マッチ箇所の情報 */
export type Hit = {
  /** マッチ箇所を含むコンテントのseries */
  series: number;
  /** コンテントが含まれるセクションの key */
  parent: number;
  /** このマッチ箇所がコンテント内で何番目のヒットか */
  offset: number;
};

/** 文書における検索マッチ情報 */
export type DocumentHits = {
  /** 文書に含まれる検索マッチの総数 */
  total: number;
  /** セクションごとの検索マッチの情報。indexはsectionのkey */
  sectionHits: { [sectionKey: string]: SectionHit };
  /** 検索の全マッチ箇所の配列。index は通し番号 */
  hits: Hit[];
};

/**
 *
 * API のレスポンスを信頼し sectionHits と contentHits の整合性は検証しない
 * @param keyword
 * @param result
 * @returns
 */
export const mapLookupResultToDocumentHits = (
  results: { keyword: string; data: LookupResult }[],
  toc: Toc | undefined,
): DocumentHits => {
  let total = 0;
  const sectionHits: { [key: string]: SectionHit } = {};
  const contentHits: ContentHit[] = [];
  results.forEach((result) => {
    // 総数を更新する
    total += result.data.total;

    // セクションごとのヒット情報を更新する
    result.data.hits.forEach((sectionHit) => {
      if (sectionHits[sectionHit.key] === undefined) {
        // 存在しない場合は新しく作る
        sectionHits[sectionHit.key] = {
          key: sectionHit.key,
          sectionHits: sectionHit.count,
          accumulatedHits: sectionHit.count + sectionHit.subsectionCount,
          sectionHitsByKeyword: { [result.keyword]: sectionHit.count },
          hitKeywordsCount: 0, // 最後にセットするので0でよい
          containsAllKeywordsMatch: false, // 最後にセットする
        };
      } else {
        // すでにある場合は元々に追加する
        const original = sectionHits[sectionHit.key];
        sectionHits[sectionHit.key] = {
          key: original.key,
          sectionHits: original.sectionHits + sectionHit.count,
          accumulatedHits: original.accumulatedHits + sectionHit.count + sectionHit.subsectionCount,
          sectionHitsByKeyword: { ...original.sectionHitsByKeyword, [result.keyword]: sectionHit.count },
          hitKeywordsCount: 0, // 最後にセットするので0でよい
          containsAllKeywordsMatch: false, // 最後にセットする
        };
      }

      // コンテントごとのヒット情報を更新する
      sectionHit.contents.forEach((contentHit) => {
        if (contentHits[contentHit.series] === undefined) {
          // 存在しない場合は新しく作る
          contentHits[contentHit.series] = {
            series: contentHit.series,
            parent: sectionHit.key,
            total: contentHit.count,
          };
        } else {
          // すでにある場合は元々に追加する
          const original = contentHits[contentHit.series];
          contentHits[contentHit.series] = {
            ...original,
            total: original.total + contentHit.count,
          };
        }
      });
    });
  });

  Object.keys(sectionHits).forEach((key: string) => {
    sectionHits[key].hitKeywordsCount = Object.values(sectionHits[key].sectionHitsByKeyword).filter(
      (c) => c > 0,
    ).length;
  });

  if (toc) {
    Object.keys(sectionHits).forEach((key: string) => {
      // いま見ているセクションがまだ探索されていないかつ全キーワードヒットなのであれば、
      // そこからルートノードに向かって再帰的に containsAllKeywordsMatch をすべて true にしていく
      if (sectionHits[key].containsAllKeywordsMatch !== true && sectionHits[key].hitKeywordsCount === results.length) {
        const numKey = parseInt(key);
        setContainsAllKeywordsMatchTrueRecursively(numKey, sectionHits, toc);
      }
    });
  }

  Object.keys(sectionHits).forEach((key: string) => {
    Object.freeze(sectionHits[key]);
  });

  return {
    total,
    sectionHits,
    // マージ作業が簡易にできるように配列の key に非連続な値を使っているので、間に生成される undefined を除去する
    hits: extentHits(contentHits.filter((hit) => hit !== undefined)),
  };
};

/**
 * PDF検索の結果を DocumentHits に変換する
 */
export function mapPdfPageHitsToDocumentHits(
  docId: string,
  toc: Record<string, TocInteractive>,
  pageHits: { wordIndex: number }[][],
  keywords: string[],
): DocumentHits {
  if (keywords.length === 0) return { hits: [], sectionHits: {}, total: 0 };

  const filtered = Object.entries(toc).filter(([key]) => key.startsWith(tocReferenceKeyPrefix(docId)));
  const rootKeys = filtered.filter(([_, value]) => value.isRoot).map(([_, value]) => value.key);

  const keyToRange = tocToPageRange(docId, rootKeys, Object.fromEntries(filtered));
  const accHits = cumulateHits(keywords, pageHits);

  const result = {
    total: accHits.reduce((prev, cur) => prev + cur[cur.length - 1], 0),
    sectionHits: Object.fromEntries(
      keyToRange.map(({ start, end, childStart }, key) => {
        // endがinfinityを許しているのでminを取る
        end = Math.min(end, accHits[0].length - 1);
        const hitKeywordsCount = accHits.reduce((acc, hits) => acc + +!!(hits[end] - hits[start]), 0);

        return [
          key,
          {
            key,
            // 下の意味: (親 section の子 section を含む hit 数) - (子 section の hit 数)
            sectionHits: accHits.reduce(
              (prev, cur) =>
                prev + (cur[end] - cur[start]) - (childStart !== undefined ? cur[end] - cur[childStart] : 0),
              0,
            ),
            accumulatedHits: accHits.reduce((prev, cur) => prev + cur[end] - cur[start], 0),
            sectionHitsByKeyword: Object.fromEntries(accHits.map((hits, i) => [keywords[i], hits[end] - hits[start]])),
            hitKeywordsCount,
            containsAllKeywordsMatch: hitKeywordsCount === accHits.length,
          },
        ];
      }),
    ),
    hits: [],
  };
  return result;
}

type TocKeyToRange = { start: number; end: number; childStart?: number }[];
/**
 * TOCからTOCの見出しが表すページの区間 [start, end) を計算する。
 * TOCには開始ページしか含まれないので書籍のページ数が infinity であるものとする
 */
function tocToPageRange(
  docId: string,
  node: number[],
  toc: Record<string, TocInteractive>,
  end = Infinity,
  keyToRange: TocKeyToRange = new Array(Object.keys(toc).length).fill({ start: 0, end: 0 }),
): TocKeyToRange {
  for (let i = 0; i < node.length; i++) {
    const childKey = node[i];
    const child = toc[tocReferenceKey(docId, childKey)];
    // 下の意味:
    // end を取る
    // 1. 右端ではない場合、
    //   1a. i 番目の start と i+1 番目の start が同じ場合（つまり1ページ以内に section が終了してしまう場合）は i 番目の start+1 を end とする
    //   1b. そうでない場合 i+1 番目の start を i 番目の end とする
    // 2. 右端のノードの場合親の end をそのままつかう
    const childEnd =
      i + 1 < node.length
        ? toc[tocReferenceKey(docId, node[i + 1])].pageSeq === child.pageSeq
          ? child.pageSeq + 1
          : toc[tocReferenceKey(docId, node[i + 1])].pageSeq
        : end;
    const childChildKey = child.children?.[0];
    const childChildStart = (childChildKey !== undefined ? toc[tocReferenceKey(docId, childChildKey)] : undefined)
      ?.pageSeq;
    keyToRange[childKey] = {
      start: child.pageSeq,
      end: childEnd,
      childStart: childChildStart,
    };
    tocToPageRange(docId, child.children, toc, childEnd, keyToRange);
  }
  return keyToRange;
}

/** ページ毎のhitの累積和を取る */
function cumulateHits(keywords: string[], pageHits: { wordIndex: number }[][]): number[][] {
  const acc: number[][] = new Array(keywords.length);
  for (let i = 0; i < keywords.length; i++) acc[i] = new Array(pageHits.length + 1).fill(0);
  for (let i = 0; i < keywords.length; i++) {
    for (let j = 0; j < pageHits.length; j++) {
      acc[i][j + 1] = acc[i][j] + pageHits[j].reduce((prev, curr) => prev + +(curr.wordIndex === i), 0);
    }
  }
  return acc;
}

/**
 * コンテンツ単位でのヒット情報を配列に展開する
 * @param contentHits
 * @returns
 */
const extentHits = (contentHits: ContentHit[]): Hit[] => {
  return contentHits.sort(byKeySorter('parent')).flatMap((contentHit) => {
    const hits = [];
    for (let offset = 0; offset < contentHit.total; offset++) {
      hits.push({ parent: contentHit.parent, series: contentHit.series, offset });
    }
    return hits;
  });
};

/**
 * 下から再帰的に containsAllKeywordsMatch を true にする
 */
const setContainsAllKeywordsMatchTrueRecursively = (
  key: number,
  sectionHits: { [key: string]: SectionHit },
  toc: Toc,
) => {
  if (key !== -1 && sectionHits[key] && sectionHits[key].containsAllKeywordsMatch === false) {
    sectionHits[key].containsAllKeywordsMatch = true;
    const parentKey = toc.byKey[key].parent;
    setContainsAllKeywordsMatchTrueRecursively(parentKey, sectionHits, toc);
  }
};

export const accumulateTotalHitsByVolumes = (result: {
  [id: string]: { [keyword: string]: LookupResult };
}): Record<string, number> =>
  Object.fromEntries(
    Object.entries(result).map(([key, hits]) => {
      return [key, Object.values(hits).reduce((acc, current) => acc + current.total, 0)];
    }),
  );
