<template>
  <div class="hit-counter-container">
    <div
      v-if="open && selfHit"
      :title="selfHit.title"
      class="hit-counter -self"
      :class="{ '-allMatched': selfHit.isAllMatched }"
    >
      {{ selfHit.count }}
    </div>
    <div
      v-if="!open && selfAccumulatedHit"
      :title="selfAccumulatedHit.title"
      class="hit-counter -accumulated"
      :class="{ '-allMatched': selfAccumulatedHit.isAllMatched }"
    >
      {{ selfAccumulatedHit.count }}
    </div>
  </div>
</template>

<script lang="ts">
import { SectionHit } from '@/utils/lookupUtils';
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import { State as MyState, FindState } from '@/store';

const countLabel = (count: number): string => (count < 1000 ? String(count) : '999+');

type HitInfo = { count: string; isAllMatched: boolean; title: string };

@Component
export default class HitCounter extends Vue {
  @Prop() sectionHit!: SectionHit;
  @Prop() open!: boolean;

  /** 文書内検索・ハイライト用データ */
  @State((state: MyState) => state.document.find) findState!: FindState;

  get selfHit(): HitInfo | null {
    if (this.sectionHit.sectionHits) {
      return {
        count: countLabel(this.sectionHit.sectionHits),
        isAllMatched: this.findState.keywords.length === this.sectionHit.hitKeywordsCount,
        title: `指定された ${this.findState.keywords.length} 個のキーワードのうち ${this.sectionHit.hitKeywordsCount} 個が合計 ${this.sectionHit.sectionHits} 箇所ヒットしました`,
      };
    }
    return null;
  }

  get selfAccumulatedHit(): HitInfo | null {
    if (this.sectionHit.accumulatedHits) {
      return {
        count: countLabel(this.sectionHit.accumulatedHits),
        // accumulated hits に「指定したキーワードすべてがヒット」したノードが含まれているか
        isAllMatched: this.sectionHit.containsAllKeywordsMatch,
        title: `指定されたキーワード${
          this.sectionHit.containsAllKeywordsMatch ? 'すべて' : 'いずれか'
        }がサブセクションまで合わせて合計 ${this.sectionHit.accumulatedHits} 箇所ヒットしました`,
      };
    }
    return null;
  }
}
</script>

<style lang="scss" scoped>
$keyword-hit: #448ed9;
$keyword-hit-dimmed: #dee7f4;
$all-keywords-hit: #fc764c;
$all-keywords-hit-dimmed: #f1e5e5;

.hit-counter-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 32px;
  border: none;
  border-bottom: 1px solid transparent;

  > .hit-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
    background: $keyword-hit;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    cursor: default;

    &.-allMatched {
      background: $all-keywords-hit;
    }

    &.-accumulated {
      border: 1.5px solid $keyword-hit;
      background: $keyword-hit-dimmed;
      color: $keyword-hit;

      &.-allMatched {
        border: 1.5px solid $all-keywords-hit;
        background: $all-keywords-hit-dimmed;
        color: $all-keywords-hit;
      }
    }
  }
}
</style>
