<template>
  <v-tooltip bottom :open-delay="300">
    <template #activator="{ on, attrs }">
      <v-col
        v-ripple="!localDisabled"
        :class="{ disabled: localDisabled }"
        title="表示モードを切り替えます"
        v-bind="attrs"
        v-on="on"
        @click="!localDisabled && $emit('click', modeToSwitch)"
      >
        <v-icon>mdi-file-swap</v-icon>
        <div class="mode">{{ modeToSwitch }}ビュー</div>
      </v-col>
    </template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="localDisabled ? disabledTooltipText : tooltipText" />
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Viewer } from '@/types/Viewer';

@Component
export default class ViewerSwitch extends Vue {
  @Prop() availableViewers!: Viewer[];
  @Prop() activeViewer!: Viewer;
  @Prop() disabled!: boolean;

  get modeToSwitch(): Viewer {
    switch (this.activeViewer) {
      case 'pdf':
        return 'web';
      case 'web':
        return 'pdf';
      default:
        throw new Error('ビュアーのモードが不正です');
    }
  }

  get localDisabled(): boolean {
    return this.disabled || this.availableViewers.includes(this.modeToSwitch) === false;
  }

  get tooltipText(): string {
    switch (this.modeToSwitch) {
      case 'pdf':
        return 'PDFファイルを使って文書を版面通り表示します<br />一部高度な機能が制限されます';
      case 'web':
        return '提供されたコンテンツを文書構造解析技術で自動解析・変換することによって<br />文書をWebブラウザ上での表示に最適化して表示します';
      default:
        throw new Error('ビュアーのモードが不正です');
    }
  }

  get disabledTooltipText(): string {
    switch (this.modeToSwitch) {
      case 'pdf':
        return 'この文書はPDFビューを利用できません';
      case 'web':
        return '準備中のため、この文書はWebビューを利用できません';
      default:
        throw new Error('ビュアーのモードが不正です');
    }
  }
}
</script>

<style lang="scss" scoped>
.mode {
  text-transform: uppercase;
}
</style>
