<template>
  <v-autocomplete
    v-model="selected"
    class="kommentar-toc-filter"
    :class="`font-size-${fontSize}`"
    :items="items"
    :filter="kommentarTocFilter"
    item-text="label"
    :item-value="itemValue"
    return-object
    dense
    outlined
    placeholder="条番号検索"
    hide-details="true"
    @input="inputHandler"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.label" />
        <v-list-item-subtitle class="subtitle" v-text="item.docTitle" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, State, Vue } from 'nuxt-property-decorator';
import * as Constants from '@/constants';
import { State as MyState } from '@/store';
import { kommentarTocFilter } from '@/utils/kommentarUtils';
import { TocInteractive } from '@/types/Viewer';

/**
 * コンメンタール書籍の TOC をフィルタリングしてジャンプするためのコンポーネント
 */
@Component
export default class KommentarTocFilter extends Vue {
  kommentarTocFilter = kommentarTocFilter;

  @Prop() fontSize!: '14' | '18' | '24' | '34';

  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;

  selected?: number;

  // 初期値が undefined だと data として認識してくれないので、 data を実装して返すようにする
  // https://github.com/vuejs/vue-class-component/issues/39
  data() {
    return {
      selected: undefined,
    };
  }

  /** 検索対象とするTOC項目のリスト（depthを見て「条」に対応する項目のみを対象とする） */
  get items(): TocInteractive[] {
    return Object.values(this.toc).filter((node) => node.depth === Constants.Document.Toc.KommentarArticleDepth);
  }

  async inputHandler(node: TocInteractive) {
    console.log('gotoToc', node);
    this.$emit('select', { docId: node.docId, key: node.key });
    await this.$nextTick();
  }

  clear() {
    this.selected = undefined;
  }

  itemValue(item: TocInteractive) {
    return `${item.docId}-${item.label}`;
  }
}
</script>

<style lang="scss" scoped>
.kommentar-toc-filter {
  flex-grow: 0;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0;
  font-size: 12px;

  &.font-size-14 {
    font-size: 11px;
  }
  &.font-size-18 {
    font-size: 13px;
  }
  &.font-size-24 {
    font-size: 18px;
  }
  &.font-size-34 {
    font-size: 24px;
  }

  ::v-deep fieldset {
    border: 0;
  }
}
.subtitle {
  font-size: #{13px * 0.8} !important;
}
</style>
