<template>
  <div class="resizable-drawer-layout" :style="{ 'grid-template-columns': gridTemplateColumns }">
    <div v-show="isDrawerOpen" class="drawer-container" :class="{ 'elevation-5': leftElevation }">
      <section class="pane">
        <slot name="drawer">
          <!-- ドロワーコンテンツが入る -->
        </slot>
      </section>
    </div>
    <div class="main-container">
      <section class="pane">
        <slot name="main">
          <!-- メインコンテンツが入る -->
        </slot>
      </section>
    </div>
    <div
      v-if="enableRightDrawer"
      v-show="isRightDrawerOpen"
      :style="{ flexBasis: `${rightDrawerWidth}px`, width: `${rightDrawerWidth}px` }"
      :class="{ 'elevation-5': rightElevation, '-overlay': isOverlayRightDrawer }"
      class="right-drawer-container"
    >
      <section class="pane">
        <slot name="right-drawer">
          <!-- 右ドロワーコンテンツが入る -->
        </slot>
      </section>
    </div>
    <div
      v-if="isDrawerOpen"
      class="resize-bar"
      :class="{ resizing }"
      :style="{ left: `${resizing ? 0 : drawerWidth}px` }"
      @mousedown="resizeStart"
      @mousemove="resizeMove"
      @mouseup="resizeEnd"
    />

    <v-btn
      fixed
      dark
      fab
      bottom
      color="grey"
      title="目次パネルを表示・非表示する"
      :style="{ left: toggleButtonPosition }"
      class="btn-toggle-drawer"
      @click="toggleDrawer"
    >
      <v-icon :class="[isDrawerOpen ? 'btn-toggle-drawer-normal' : 'btn-toggle-drawer-rotate']">
        mdi-chevron-double-left
      </v-icon>
    </v-btn>
    <v-btn
      v-if="enableRightDrawer"
      fixed
      dark
      fab
      bottom
      color="grey"
      :style="{ right: rightToggleButtonPosition }"
      class="btn-toggle-drawer"
      @click="toggleRightDrawer"
    >
      <v-icon :class="[isRightDrawerOpen ? 'btn-toggle-drawer-normal' : 'btn-toggle-drawer-rotate']">
        mdi-chevron-double-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from 'nuxt-property-decorator';
import type SearchBar from '@/components/search-bar.vue';

/**
 * ドロワーがあり、ドラッグでドロワーの幅を変更できるレイアウト
 * ドロワーとメインエリアは個別にスクロールすることでき、フッターは固定表示になっている
 * TODO: ドロワートグル機能を実装する
 * TODO: ドロワートグルの幅の取り扱いを整理する（初期値だけを Props で受け取る or state を使わずリアクティブに実装する）
 */
@Component
export default class LayoutResizableDrawer extends Vue {
  /** 右ドロワーが表示中か非表示かのフラグ */
  @Prop({ default: false }) enableRightDrawer!: boolean;

  @Prop() initialRightDrawerOpen!: boolean | 'unless-overlay';

  @Prop() leftElevation?: boolean;

  @Prop() rightElevation?: boolean;

  @Inject('$search-bar') searchBar!: InstanceType<typeof SearchBar>;

  get gridTemplateColumns(): string {
    const columns = [];
    if (this.isDrawerOpen) {
      columns.push(this.drawerWidth + 'px');
    }
    columns.push('1fr');
    if (this.enableRightDrawer && this.isRightDrawerOpen && !this.isOverlayRightDrawer) {
      columns.push('1fr');
    }

    return columns.join(' ');
  }

  /** ドロワー部分の幅 */
  get drawerWidth(): number {
    return this.isDrawerOpen ? this.resizeBarPosition : 0;
  }

  get drawerFlexBasis(): string {
    return `${this.drawerWidth}px`;
  }

  get rightDrawerWidth(): number {
    if (!this.isRightDrawerOpen) return 0;
    return this.isOverlayRightDrawer
      ? (this.$vuetify.breakpoint.width - this.drawerWidth) * 0.8
      : (this.$vuetify.breakpoint.width - this.drawerWidth) / 2;
  }

  get isOverlayRightDrawer(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  /** トグルボタンの位置を px 付きで返す */
  get toggleButtonPosition(): string {
    return this.isDrawerOpen ? `${this.drawerWidth - 16}px` : '6px';
  }

  /** トグルボタンの位置を px 付きで返す */
  get rightToggleButtonPosition(): string {
    return this.isRightDrawerOpen ? `${this.rightDrawerWidth - 16}px` : '6px';
  }

  /** リサイズバーの位置（ドロワー非表示でも保持しておく） */
  resizeBarPosition = 256;

  /** ドロワーが表示中か非表示かのフラグ */
  isDrawerOpen = true;
  @Watch('isDrawerOpen', { immediate: true })
  emitLeftDrawer(val: boolean) {
    if (!this.searchBar) {
      console.error('Cannot find provided search-bar component');
      return;
    }

    // search-bar も同時に表示・非表示の制御を行う
    if (val) {
      this.searchBar.$el.removeAttribute('style');
    } else {
      this.searchBar.$el.setAttribute('style', 'display: none');
    }
  }

  /** ドロワーが表示中か非表示かのフラグ */
  isRightDrawerOpen = true;
  @Watch('isRightDrawerOpen', { immediate: true })
  emitRightDrawer(): void {
    if (this.isRightDrawerOpen) {
      this.$emit('right-drawer-open');
    } else {
      this.$emit('right-drawer-close');
    }
  }

  /** ドロワーがリサイズしているかのフラグ。リサイズ中は位置情報を持つ */
  resizing: number | false = false;

  mounted() {
    this.isRightDrawerOpen =
      this.initialRightDrawerOpen === 'unless-overlay' ? !this.isOverlayRightDrawer : this.initialRightDrawerOpen;
  }

  onColumnResized(width: number) {
    this.resizeBarPosition = width;
  }

  resizeStart() {
    this.resizing = this.resizeBarPosition;
    document.documentElement.style.setProperty(
      '--drawer-width-resizing',
      this.isDrawerOpen ? `${this.resizing}px` : '0px',
    );
  }

  resizeMove(event: MouseEvent) {
    if (this.resizing) {
      this.resizing = event.clientX;
      document.documentElement.style.setProperty(
        '--drawer-width-resizing',
        this.isDrawerOpen ? `${this.resizing}px` : '0px',
      );
    }
  }

  resizeEnd() {
    if (this.resizing) {
      this.resizeBarPosition = this.resizing;
      this.resizing = false;
    }
  }

  /** 情報パネルの表示・非表示 */
  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  /** 情報パネルの表示・非表示 */
  toggleRightDrawer() {
    this.isRightDrawerOpen = !this.isRightDrawerOpen;
  }
}
</script>

<style lang="scss" scoped>
@import '../../layouts/default.scss';

.resizable-drawer-layout {
  display: grid;
  width: 100%;
  max-width: 100%;
  padding: 0;

  section.pane {
    height: 100dvh;
    overflow-y: auto;
  }
}

.drawer-container {
  z-index: $layer-layout-resizable-drawer-left-drawer;
  flex-shrink: 0;
  flex-grow: 0;
}

.right-drawer-container {
  flex-shrink: 0;
  flex-grow: 0;
  background: white;
  border-left: 1px solid #e0e0e0;

  &.-overlay {
    position: fixed;
    right: 0;
    z-index: $layer-layout-resizable-drawer-right-drawer;
  }
}

.drawer-container,
.main-container,
.right-drawer-container {
  transition: height $default-transition-duration linear;

  position: relative;
  height: calc(100vh - var(--header-area-offset));
  padding: 0;

  overflow: auto;
}

.resize-bar {
  transition: top $default-transition-duration linear;
  transition: height $default-transition-duration linear;

  position: fixed;
  z-index: $layer-layout-resizable-drawer-resize-bar;
  width: 20px;
  cursor: ew-resize;

  top: var(--header-area-offset);
  height: calc(100vh - var(--header-area-offset));

  &.resizing {
    left: 0;
    width: 100vw;
  }

  &.resizing::before,
  &:hover::before {
    display: block;
    width: var(--drawer-width-resizing);
    height: 100%;
    border-right: 8px solid change-color($legalscape-navy, $alpha: 0.5);
    content: '';
  }

  &:hover:not(.resizing)::before {
    width: 50%;
  }
}

.btn-toggle-drawer {
  z-index: $layer-layout-btn-toggle-drawer !important;

  .btn-toggle-drawer-normal {
    transform: rotate(0deg);
  }
  .btn-toggle-drawer-rotate {
    transform: rotate(900deg);
  }
}
</style>
