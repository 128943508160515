<template>
  <div v-show="attachment !== null" class="attachment-viewer">
    <iframe :src="attachment != null ? attachment.url : ''" class="attachment-viewer-content" />

    <v-btn fixed dark fab bottom color="grey" class="close-button" @click="close()">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-chip v-if="showCloseChip" class="ma-1 close-chip" close @click:close="closeChip()">
      本文に戻るにはこの下のボタンを押します
    </v-chip>
  </div>
</template>

<script lang="ts">
import { Attachment } from 'wklr-backend-sdk/models';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

const CHIP_ALREADY_SHOWED_KEY = 'attachment-viewer-close-chip-storage-key';

@Component
export default class AttachmentViewer extends Vue {
  @Prop()
  attachment!: Attachment | null;

  showCloseChip = false;
  mounted(): void {
    // $sessionStorageがnullのとき（あるのか？）、毎回出る or 1回も出ない なら後者のほうが良いと判断しtrueにしている
    const chipAlreadyShowed = this.$sessionStorage ? this.$sessionStorage.getItem(CHIP_ALREADY_SHOWED_KEY) : 'true';
    this.showCloseChip = !chipAlreadyShowed;
  }

  close(): void {
    this.$emit('update:attachment', null);
  }

  closeChip(): void {
    if (this.$sessionStorage) {
      this.$sessionStorage.setItem(CHIP_ALREADY_SHOWED_KEY, 'true');
    }
    this.showCloseChip = false;
  }
}
</script>

<style lang="scss" scoped>
.attachment-viewer {
  width: 100%;
  height: 100%;
}

.attachment-viewer-content {
  width: 100%;
  height: 100%;
  border-width: 0;
}

.close-button {
  right: 32px;
}

.close-chip {
  position: fixed;
  bottom: calc(16px + 56px + 10px);
  right: 32px;
}
</style>
