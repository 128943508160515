<template>
  <div class="hit-counter-v2" :class="{ '-show': isFindStateActivated }">
    <v-menu v-if="nodeInfo.sectionHit" open-on-hover open-delay="150" top offset-y left>
      <template #activator="{ on, attrs }">
        <div
          :class="{
            '-hit-all': containsAllKeywordsMatch,
            '-has-open-children': showAsHavingChildren && open,
          }"
          :style="{ left: nodeInfo.logicalDepth * 10 + 'px' }"
          v-bind="attrs"
          v-on="on"
          v-text="hitCount"
        />
      </template>
      <div class="pa-2 white caption text--darken-4" :class="{ 'blue--text': containsAllKeywordsMatch }">
        <template v-if="findState.keywords.length === 1"> {{ accumulatedHits }}箇所にヒット </template>
        <template v-else-if="containsAllKeywordsMatch"> 指定したキーワードの<strong>すべて</strong>がヒット </template>
        <template v-else>指定したキーワードの<strong>一部</strong>がヒット</template>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import { TocInteractive } from '@/types/Viewer';
import { State as MyState, FindState } from '@/store';

@Component
export default class HitCounterV2 extends Vue {
  @Prop() nodeInfo!: TocInteractive;
  @Prop() showAsHavingChildren!: boolean;
  @Prop() open!: boolean;

  @State((state: MyState) => state.document.find) findState!: FindState;

  /** 文書内検索の結果を表示中かどうか */
  get isFindStateActivated(): boolean {
    return this.findState.hit.documentHits != null;
  }

  /** 表示するヒット件数 */
  get hitCount(): string | number {
    return this.accumulatedHits > 999 ? '999+' : this.accumulatedHits;
  }

  get accumulatedHits(): number {
    return this.nodeInfo.sectionHit?.accumulatedHits || 0;
  }

  get containsAllKeywordsMatch(): boolean {
    return this.nodeInfo.sectionHit?.containsAllKeywordsMatch || false;
  }
}
</script>

<style lang="scss">
.hit-counter-v2 {
  position: relative;
  flex: 1 1 0;
  height: 26px;
  text-align: center;
  font-size: 10px;
  transition: flex-basis 0.25s ease-in-out;

  &.-show {
    flex-basis: 26px;
  }

  > div {
    position: relative;
    margin-top: 1px;
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
    border-radius: 2em;
    background: white;
    line-height: 23px;

    &.-hit-all {
      margin-top: 0;
      margin-right: -2px;
      margin-left: -2px;
      width: 28px;
      height: 28px;
      border-color: #22c;
      color: #22c;
      line-height: 26px;

      &::after {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        display: block;
        border: 1px solid #88c;
        border-radius: 2em;
        content: '';
      }
    }

    &.-has-open-children {
      opacity: 0.5;
    }
  }
}
</style>
