import { Component, Prop, Vue, Watch, State } from 'nuxt-property-decorator';
import DocumentListItem from '@/components/document-list-item.vue';
import TocNodesPanel from '@/components/toc-panel/toc-nodes-panel.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';
import DocumentBinderPanel from '@/components/toc-panel/document-binder-panel.vue';
import AttachmentList from '@/components/toc-panel/attachment-list.vue';
import ViewerSwitch from '@/components/toc-panel/viewer-switch.vue';
import { Viewer, TocInteractive } from '@/types/Viewer';
import { OutlineItem } from '@/types/pdfjs';
import { PDFPageIdentifier } from '@/types/pdf-page-identifier';
import { DocumentConfig } from '@/types/document-config';
import { State as MyState, FindState } from '@/store';
import { PdfNavigationArgs, WebNavigationArgs } from '@/pages/document/-document';
import {
  Attachment,
  BinderItemOfDocument,
  BookmarkTypePdf,
  BookmarkTypeWeb,
  BookmarkWithNaturalId,
  HighlightWithNaturalId,
  QuickAccessItemTypePdf,
  QuickAccessItemTypeWeb,
  QuickAccessItemWithNaturalId,
} from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';
import KommentarTocFilter from '@/components/toc-panel/kommentar-toc-filter.vue';
import AllVolumesTocNodesPanel from '@/components/toc-panel/all-volumes-toc-nodes-panel.vue';
import {
  AllVolumesToc,
  DocRecordExtended,
  hardcodedVolumeInfo,
  tocReferenceKey,
  UnaccessibleDocRecordExtended,
} from '@/utils/tocUtils';
import { Nullable } from '@/types/nullable';
import { debounce } from 'throttle-debounce';
import { Location } from 'vue-router';
import NO_IMAGE from '@/assets/noimage.png';
import { formatYmd } from '@/utility';
import FormatFileExistingNotice from '@/components/alert/format-file-existing-notice.vue';

export interface DocumentCollectionItems {
  binderItems: BinderItemOfDocument[];
  quickAccessItems: QuickAccessItemWithNaturalId[];
}

// TEMP: yukako's check
// broken
// prettier-ignore
const uglyTocPdfs = [
  '9784785722388', '9784788276932', '9784788276741', '9784788281714', '9784788283534',
  '9784788283039', '9784788283718', '9784788283701', '9784788280922', '9784788282032',
  '9784785723330', '9784788283312', '9784785725389', '9784788281677', '9784785726157',
  '9784788284333', '9784788284678', '9784785720919', '9784785726973', '9784785723439',
  '9784785726140', '9784785724436', '9784785720605', '9784785721381', '9784785725976',
  '9784788280731', '9784785725242', '9784785723194', '9784785719746', '9784785726621',
  '9784785724689', '9784785723828', '9784785723170', '9784785723644', '9784785720131',
  '9784785726058', '9784817841902', '9784788281165', '9784785722357', '9784817843821',
  '9784785721398', '9784785720087', '9784785720599', '9784788282834', '9784788282896',
  '9784788281158', '9784788281042', '9784785723248', '9784788279797', '9784324099728',
  '9784788279612', '9784788283442', '9784788284456', '9784785726867', '9784785725853',
  '9784785727260', '9784785723842',
]

@Component({
  components: {
    AllVolumesTocNodesPanel,
    AttachmentList,
    DocumentBinderPanel,
    DocumentListItem,
    FormatFileExistingNotice,
    KommentarTocFilter,
    ShareUrlDialog,
    ViewerSwitch,
    TocNodesPanel,
  },
})
export default class TocPanel extends Vue {
  $refs!: {
    'kommentar-toc-filter': KommentarTocFilter;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  formatYmd = formatYmd;
  isAccessible = isAccessible;

  /** 現在利用中のビューアー */
  @State((state) => state.document.activeViewer) activeViewer!: Viewer;

  /** 現在表示中の文書 */
  @Prop() record!: DocRecordExtended | UnaccessibleDocRecordExtended;

  /** 現在表示中の文書で利用可能なビューアー */
  @Prop() availableViewers!: Viewer[];

  /** PDFのoutline */
  @Prop() outline!: (OutlineItem & { pageNum: number })[] | null;

  /** 表示中のattchment */
  @Prop() displayedAttachment!: Attachment | null;

  /** バインダーを無効化したい場合 */
  @Prop({ type: Boolean }) disableBinder?: boolean;

  /** 文書内検索・ハイライト用データ */
  @State((state: MyState) => state.document.find) find!: FindState;

  /** 閲覧画面の設定 */
  @State((state: MyState) => state.persistent.documentConfig) config!: DocumentConfig;

  /** パネルの表示 */
  panel: 'toc' | 'info' | 'binder' | 'attachment' = 'toc';

  /** TOC */
  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;

  /** TOCの情報をどこから取得したか */
  tocSource: 'db' | 'pdf' = 'db';

  /** Web view: in-viewなセクションのkey */
  inViewSections: number[] = [];

  /** Web view: in-viewなセクションとその祖先セクションのkey */
  inViewSectionsWithAncestors: number[] = [];

  focusedNode: Nullable<number> = null;

  /** PDF view: in-viewなページの番号 (1-index) */
  inViewPage = 1;

  /** ドキュメントに含まれるバインダー一覧 */
  documentCollectionItems: DocumentCollectionItems = {
    binderItems: [],
    quickAccessItems: [],
  };

  /** PDF だった場合に root として利用される key の一覧 */
  pdfRootNodeKeys: number[] = [];

  /** for Vue */
  readonly noImage = NO_IMAGE;

  /** バインダー機能が無効な場合はクイックアクセスが名称になる */
  get textBinderOrQuickAccess(): string {
    return this.$auth.permissions.binder ? 'バインダー' : 'クイックアクセス';
  }

  get rootNodeKeys(): number[] {
    return this.tocSource === 'pdf' ? this.pdfRootNodeKeys : this.record.toc?.children || [];
  }

  get allVolumesToc(): AllVolumesToc | null {
    if (this.record.allVolumesToc === undefined) return null;
    return this.record.allVolumesToc.map((toc) => ({
      docTitle: toc.docTitle,
      docId: toc.docId,
      volumeInfo: hardcodedVolumeInfo[toc.docId] ?? { volumeNumber: toc.docTitle },
      isSeqUnique: false,
      isLaw: false,
      folioPerSeq: null,
      rootNodeKeys: toc.children || [],
      totalSearchHits: this.find.hit.totalHitsByVolume[toc.docId],
    }));
  }

  mounted(): void {
    this.$store.commit('setTocList', {
      toc: this.record.tocInteractive,
      openByDefault: this.record.isKommentar ? 'all' : 'root',
    });
    if (isAccessible(this.record) && !this.record.isWebViewAvailable && !this.record.pdfFileURI) {
      this.panel = 'attachment';
    }
  }

  async showBinderHandler(): Promise<void> {
    if (this.panel !== 'binder') {
      this.panel = 'binder';
      this.updateBinderItems();
    }
  }

  async updateBinderItems(): Promise<void> {
    // クイックアクセス・バインダーのいずれかの取得に失敗しても閲覧し続けられるようにする。
    // 失敗した場合更新されないが、トーストで通知することで取得に問題があったと明示している
    const result = await this.$repositories.docs.getMyCollectionItemsForDocument(this.record.id);

    if (result.quickAccessItemsResult.isFailure()) {
      this.$toast.error('クイックアクセスアイテムの取得に失敗しました。');
    } else {
      this.documentCollectionItems.quickAccessItems = result.quickAccessItemsResult.value.partial;
    }

    if (this.$auth.permissions.binder) {
      if (result.binderItemsResult.isFailure()) {
        this.$toast.error('バインダーアイテムの取得に失敗しました。');
      } else {
        this.documentCollectionItems.binderItems = result.binderItemsResult.value;
      }
    }
    // binder パーミッションがない場合は結果にかかわらず binderItems は更新しない
    // FIXME: パーミッションがなければそもそもバインダーAPIを叩かないようにする
  }

  @Watch('outline')
  buildPDFToc(): void {
    if (
      !this.outline ||
      uglyTocPdfs.includes(this.record.id) ||
      !isAccessible(this.record) ||
      this.record.isWebViewAvailable ||
      this.record.toc
    ) {
      return;
    }

    const pdfToc: Record<string, TocInteractive> = {};
    const stack: (OutlineItem & { parent?: number; pageNum?: number })[] = [...this.outline].reverse();
    let key = 0;
    while (stack.length > 0) {
      const { dest, title, parent: parentUndefined, items, pageNum } = stack.pop()!;
      const parent = parentUndefined !== undefined ? parentUndefined : -1;
      const depth =
        parentUndefined !== undefined ? (pdfToc[tocReferenceKey(this.record.id, parentUndefined)]?.depth || 0) + 1 : 0;

      const isRoot = parent === -1;

      pdfToc[tocReferenceKey(this.record.id, key)] = {
        docId: this.record.id,
        docTitle: this.record.title,
        key,
        label: title,
        parent,
        pageSeq: pageNum ?? 0,
        depth,
        logicalDepth: depth,
        children: [],
        pdfPageScrollTo: () => this.$emit('pdfNavigate', { dest }),
        isLeaf: true,
        isRoot,
        open: isRoot,
        active: false,
        sectionHit: null,
        folioLabel: '', // PDF の ToC にはページ番号を表示していない。必要なら処理を追加する
      };
      if (isRoot) {
        this.pdfRootNodeKeys.push(key);
      } else {
        pdfToc[tocReferenceKey(this.record.id, parent)].children.push(key);
        pdfToc[tocReferenceKey(this.record.id, parent)].isLeaf = false;
      }

      items.reverse().forEach((item) => stack.push({ ...item, parent: key }));
      key += 1;
    }

    this.tocSource = 'pdf';
    this.$store.commit('setTocList', { toc: pdfToc, openByDefault: 'root' });
  }

  innerUpdateInViewSections = debounce(
    100, // interval
    function (
      this: TocPanel,
      inViewSections: number[],
      inViewSectionsWithAncestors: number[],
      focusedNode: number | null,
    ): void {
      this.inViewSections = inViewSections;
      this.inViewSectionsWithAncestors = inViewSectionsWithAncestors;
      this.focusedNode = focusedNode;
      this.$store.commit('updateActiveToc', { docId: this.record.id, activeKeys: inViewSectionsWithAncestors });
    },
  );

  /**
   * Web view: in-viewなセクションが変更された
   * @param inViewSections keyの配列
   */
  updateInViewSections({
    inViewSections,
    inViewSectionsWithAncestors,
  }: {
    inViewSections: number[];
    inViewSectionsWithAncestors: number[];
  }): void {
    this.innerUpdateInViewSections(
      inViewSections,
      inViewSectionsWithAncestors,
      inViewSections.length > 0 ? inViewSections[inViewSections.length - 1] : null,
    );
  }

  /**
   * PDF view: ページが表示された
   * @param page PDFPageIdentifier
   * @param cancelSearch PDF.jsの検索ハイライトでのスクロールを強制キャンセルするかどうか
   */
  updateInViewPage(page: PDFPageIdentifier | { pageNumber: number }): void {
    console.log('updateInViewPage', 'called');
    this.inViewPage = page.pageNumber;

    /** ページ番号 (0-index) */
    const pageNumber = page.pageNumber - 1;

    if (!this.record.toc) return;
    if (!this.toc || this.tocSource !== 'db') {
      return;
    }

    let first: number | undefined;
    let key = 0;

    const inViewSections = new Set<number>();
    const inViewSectionsWithAncestors = new Set<number>();

    // 今アクティブなページが存在しうる範囲より上をスキップする
    for (; key < this.record.toc.byKey.length - 1; ++key) {
      // ↑ここでlength - 1にしておくことで、境界処理を片方省ける
      // （一番最後の項目より下を見ているときでも一番最後の項目をactiveにできる）
      if (this.record.toc.byKey[key].pageSeq > pageNumber) {
        if (key > 0) {
          --key;
        }

        break;
      } else if (this.record.toc.byKey[key].pageSeq === pageNumber) {
        break;
      }
    }
    for (; key < this.record.toc.byKey.length; ++key) {
      if (this.record.toc.byKey[key].pageSeq > pageNumber) {
        // 今アクティブなページが存在しうる範囲より下になったので抜ける
        break;
      } else {
        inViewSections.add(key);
        inViewSectionsWithAncestors.add(key);

        // 親にさかのぼる
        let cursor = key;
        while (this.record.toc.byKey[cursor]) {
          inViewSectionsWithAncestors.add(cursor);
          cursor = this.record.toc.byKey[cursor].parent;
        }

        if (first === undefined) {
          first = key;
        }
      }
    }

    this.innerUpdateInViewSections(Array.from(inViewSections), Array.from(inViewSectionsWithAncestors), first || null);
  }

  /** 表示切替ダイアログで何かを選択した */
  switchViewer(viewer: Viewer): void {
    this.$emit('switchViewer', viewer);
  }

  clickBookmarkHandler(item: BookmarkWithNaturalId | QuickAccessItemWithNaturalId): void {
    switch (item.viewType) {
      case 'web':
        this.gotoWebBookmark(item);
        break;
      case 'pdf':
        this.gotoPdfBookmark(item);
        break;
    }
  }

  async gotoWebBookmark(item: BookmarkTypeWeb | QuickAccessItemTypeWeb): Promise<void> {
    if (!this.availableViewers.includes('web')) {
      this.$toast.error('この文献のWebビューは利用できなくなりました');
      return;
    }

    const flash = this.activeViewer === 'web';
    const args: WebNavigationArgs = { type: 'web', key: item.key, series: null, flash };
    this.$emit('visitSection', args);
  }

  async gotoPdfBookmark(item: BookmarkTypePdf | QuickAccessItemTypePdf): Promise<void> {
    if (!this.availableViewers.includes('pdf')) {
      this.$toast.error('この文献のPDFビューは利用できなくなりました');
      return;
    }

    const args: PdfNavigationArgs = { type: 'pdf', dest: item.pageSeq };
    this.$emit('visitSection', args);
  }

  clickHighlightHandler(item: HighlightWithNaturalId): void {
    const series = item.highlightedContents[0] !== undefined ? item.highlightedContents[0].series : null;
    const flash = this.activeViewer === 'web';
    const args: WebNavigationArgs = { type: 'web', key: item.key, series: series, flash };
    this.$emit('visitSection', args);
  }

  gotoToc({ docId, key }: { docId: string; key: number }): void {
    if (docId === this.record.id) {
      this.$emit('scrollToSection', key);
    } else {
      const route: Location = {
        path: `/document/${docId}`,
        query: { q: this.$route.query.q || undefined, view: this.activeViewer },
        hash: `key=${key}`,
      };
      this.$router.push(route);
    }
  }

  tocFilterSelectHandler({ docId, key }: { docId: string; key: number | undefined }): void {
    if (key === undefined) {
      return;
    }
    this.gotoToc({ docId, key });
  }

  clearKommentarTocFilter() {
    this.$refs['kommentar-toc-filter']?.clear();
  }

  // 直前のフォーカス変更時にスクロールをキャンセルしたかどうかのフラグ
  // ホバーによる自動スクロールをキャンセルした場合 focusedNode に移動していないので変更がなくてもスクロール処理を発火させたい
  pendingScroll = false;

  @Watch('focusedNode')
  changeFocusedNodeHandler(val: Nullable<number>, oldVal: Nullable<number>) {
    if (val === null) return;
    if (val === oldVal && !this.pendingScroll) return;
    this.pendingScroll = false;
    this.$nextTick(() => {
      // 操作中に動かないようにホバー時は自動スクロールしない
      if (this.$el.querySelector('#toc-root')?.matches(':hover')) {
        this.pendingScroll = true;
        return;
      }
      const element = this.$el.querySelector(`[data-toc-node="${tocReferenceKey(this.record.id, val)}"]`);
      if (element instanceof HTMLLIElement) {
        element.scrollIntoView({ block: 'center' });
      }
    });
  }
}
