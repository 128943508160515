<template>
  <div class="citation-panel d-flex flex-column">
    <h2 class="header">
      <v-btn v-if="referenceDocRecord" icon class="closeWebViewer" @click.native="closeWebViewer">
        <v-icon size="28" color="#333333">mdi-chevron-left</v-icon>
      </v-btn>
      <viewer-link v-if="referenceDocRecord" :href-document="referenceDocRecord">
        {{ header }}
      </viewer-link>
      <template v-else>
        {{ header }}
      </template>
    </h2>
    <template v-if="!referenceDocId">
      <v-tabs
        v-model="currentTab"
        grow
        show-arrows
        optional
        :color="lsPrimaryColor"
        height="48"
        class="flex-grow-0 flex-shrink-0"
      >
        <v-tab v-for="{ id, label } in tabs" :key="id" :href="`#${id}`" :disabled="!citationCountForTab(id)">
          <v-badge tile :content="citationCountForTab(id)">
            {{ label }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <div class="tab-items-container flex-grow-1">
        <div v-if="loading" class="loading-overray d-flex justify-center align-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <v-tabs-items :value="currentTab">
          <v-tab-item value="kommentarSupplementary">
            <citation-panel-kommentar-supplementary
              class="scrollable"
              :selected="currentTab === 'kommentarSupplementary'"
              :loading.sync="loading"
              :document-id="documentId"
              :section-keys="sectionKeysWithAncestorsAndDescendants"
            />
          </v-tab-item>
          <v-tab-item value="book">
            <citation-panel-book
              class="scrollable"
              :selected="currentTab === 'book'"
              :loading.sync="loading"
              :document-id="documentId"
              :section-keys="sectionKeysWithAncestorsAndDescendants"
              :has-kommentar="hasKommentar"
            />
          </v-tab-item>
          <v-tab-item value="law">
            <citation-panel-law
              class="scrollable"
              :selected="currentTab === 'law'"
              :loading.sync="loading"
              :document-id="documentId"
              :section-keys="sectionKeysWithAncestorsAndDescendants"
            />
          </v-tab-item>
          <v-tab-item value="publicComment">
            <citation-panel-public-comment
              class="scrollable"
              :selected="currentTab === 'publicComment'"
              :loading.sync="loading"
              :document-id="documentId"
              :section-keys="sectionKeysWithAncestorsAndDescendants"
            />
          </v-tab-item>
          <v-tab-item value="guideline">
            <citation-panel-guideline
              class="scrollable"
              :selected="currentTab === 'guideline'"
              :loading.sync="loading"
              :document-id="documentId"
              :section-keys="sectionKeysWithAncestorsAndDescendants"
            />
          </v-tab-item>
          <v-tab-item value="none" :transition="false" :reverse-transition="false">
            <div class="citation-panel-none d-flex justify-center align-center">
              <p>このセクションを参照している文書はLegalscape上に存在しません</p>
            </div>
          </v-tab-item>
          <v-tab-item value="error" :transition="false" :reverse-transition="false">
            <div class="citation-panel-error d-flex justify-center align-center">
              <p>参考文献の読み込みに失敗しました</p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>

    <web-viewer
      v-if="referenceDocRecord"
      ref="webViewer"
      :record="referenceDocRecord"
      :initial-key="referenceKey"
      :initial-series="referenceSeries"
      :hide-toolbar="true"
      style="flex: 1 1 0"
      class="reference-wrapper"
      :emphasized="true"
      :is-preview="true"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
import WebViewer from '@/components/viewer/web-viewer.vue';
import CitationPanelBook from '@/components/citation-panel-book.vue';
import CitationPanelLaw from '@/components/citation-panel-law.vue';
import CitationPanelGuideline from '@/components/citation-panel-guideline.vue';
import CitationPanelPublicComment from '@/components/citation-panel-public-comment.vue';
import CitationPanelKommentarSupplementary from '@/components/citation-panel-kommentar-supplementary.vue';
import ViewerLink from '@/components/viewer-link.vue';
import { generateReferenceLocation } from '@/utils/urlUtils';
import { CitationCounts, DocRecord, Toc } from 'wklr-backend-sdk/models';
import { AggregatedCitationCount } from '@/pages/document/-document';

type CitationTabs = keyof AggregatedCitationCount;

export type Tabs = CitationTabs | 'none' | 'error';

type Tab = { id: CitationTabs; label: string };

const tabs4LegalWeb: Tab[] = [
  { id: 'book', label: '関連書籍' },
  { id: 'law', label: '関連法令' },
  { id: 'publicComment', label: 'パブコメ' },
  { id: 'guideline', label: 'ガイドライン' },
];

const tabs4Kommentar: Tab[] = [{ id: 'kommentarSupplementary', label: '補巻' }];

@Component({
  components: {
    CitationPanelBook,
    CitationPanelGuideline,
    CitationPanelKommentarSupplementary,
    CitationPanelLaw,
    CitationPanelPublicComment,
    WebViewer,
    ViewerLink,
  },
})
export default class CitationPanel extends Vue {
  readonly lsPrimaryColor = '#4A5C97';
  $refs!: {
    tabContainer: HTMLDivElement;
    webViewer: WebViewer;
  };

  @Prop() documentId?: string;
  @Prop() sectionKey?: number;
  @Prop() sectionKeysWithAncestorsAndDescendants?: number[];
  @Prop() sectionTitle?: string;
  @Prop() toc?: Toc;

  @Prop() referenceDocId!: string;
  @Prop() referenceKey!: number | null;
  @Prop() referenceSeries!: number | null;
  @Prop() citationCounts!: CitationCounts | null;
  @Prop() showKommentarSupplementary!: boolean;
  @Prop() isHide!: boolean;

  referenceDocRecord: DocRecord | null = null;

  get tabs(): Tab[] {
    if (this.showKommentarSupplementary) {
      return [...tabs4LegalWeb, ...tabs4Kommentar];
    }
    return tabs4LegalWeb;
  }

  tab: Tabs = 'book';
  loading = false;

  mounted(): void {
    this.moveToreferencePosition();
  }

  get currentTab(): Tabs | null {
    if (this.isHide) {
      return null;
    } else {
      return this.tab;
    }
  }
  set currentTab(value: Tabs | null) {
    if (value === null) {
      this.isHide = true;
    } else {
      this.isHide = false;
      this.tab = value;
    }
  }

  get hasKommentar(): boolean {
    return (
      this.citationCounts !== null && this.citationCounts.kommentar !== undefined && this.citationCounts.kommentar > 0
    );
  }

  setTab(tab: Tabs): void {
    this.tab = tab;
  }

  @Watch('isHide', { immediate: true })
  @Watch('citationCounts', { immediate: true })
  updateTab(): void {
    const citationCounts = this.citationCounts;
    if (this.isHide || citationCounts === null) return;
    if (this.tab === 'none' || this.tab === 'error' || citationCounts[this.tab] === 0) {
      this.tab =
        this.tabs
          .map((tab) => tab.id)
          .find((tab) => {
            const count = citationCounts[tab];
            if (!count) return false;
            return count > 0;
          }) || 'none';
    }
  }

  citationCountForTab(tabName: CitationTabs): string {
    if (!this.citationCounts) {
      return '';
    }

    const count =
      tabName === 'book'
        ? this.citationCounts.book + (this.citationCounts.kommentar || 0)
        : this.citationCounts[tabName];
    if (count === 0 || count === undefined) {
      return '';
    } else if (count >= 100) {
      return '99+';
    } else {
      return count.toString();
    }
  }

  get header(): string {
    if (this.referenceDocRecord) {
      return this.referenceDocRecord.title;
    }

    if (this.sectionTitle) {
      return `参考文献（${this.sectionTitle}）`;
    }
    return '';
  }

  @Watch('referenceKey')
  @Watch('referenceSeries')
  moveToreferencePosition(): void {
    if (this.$refs.webViewer) {
      this.$refs.webViewer.visitSection(this.referenceKey || 0, this.referenceSeries, { flash: true });
    }
  }

  closeWebViewer(): void {
    this.$router.push(
      generateReferenceLocation(location.href, {
        reference_doc: undefined,
        reference_key: undefined,
        reference_series: undefined,
      }),
    );
  }

  @Watch('referenceDocId', { immediate: true })
  async fetchReferenceDocRecord(): Promise<void> {
    if (this.referenceDocId) {
      this.referenceDocRecord = await this.$repositories.docs.get(this.referenceDocId);
    } else {
      this.referenceDocRecord = null;
    }
  }
}
</script>

<style lang="scss">
.citation-panel {
  padding: 8px 32px;
  height: 100%;

  h2.header {
    padding: 15px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.4;
  }

  .closeWebViewer {
    text-decoration: none;
  }

  .v-tabs .v-tabs-bar a.v-tab {
    font-size: 14px;
    line-height: 21px;

    &.v-tab--active {
      font-weight: bold;
    }

    &:not(.v-tab--active) {
      color: #757575;
    }

    &.v-tab--disabled {
      color: #e2e2e2;
    }
  }

  div.tab-items-container {
    overflow: hidden;
    flex-basis: 1 1 0;
    box-sizing: border-box;
    margin-top: 18px;
    margin-bottom: 16px;
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    background: #fefefe;

    div.v-window,
    div.v-window-item,
    .scrollable {
      height: 100%;
    }
  }

  .reference-wrapper {
    box-sizing: border-box;
    padding: 8px 0px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    &.webview-wrapper {
      overflow: hidden;
      height: auto;
      .wrap-document-body {
        height: 100%;
      }
    }
    // プレビューでのクリックはまだ考えられてないので、クリックできなそうな見た目にしておく
    a {
      color: unset;
      text-decoration: none;
      cursor: text;
    }
  }

  .loading-overray,
  .citation-panel-none,
  .citation-panel-error {
    width: 100%;
    height: 100%;
    padding-right: 24px; // TODO: .citation-panel-tab-itemのpadding-leftを子要素に持っていくと不要になる
  }

  .v-tab .v-badge__badge {
    height: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #1976d2;
    border: 2px solid #ffffff;
    border-radius: 4px;

    vertical-align: middle;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
  }

  .v-tab--disabled .v-badge__badge {
    display: none;
  }
}
</style>
