<template>
  <ul class="reference-count-panel">
    <li
      v-if="showKommentarSupplementary"
      class="countItem"
      :class="{ '-disabled': !kommentarSupplementaryCount }"
      @click="kommentarSupplementaryCount !== null && $emit('click', 'kommentarSupplementary')"
    >
      <document-type-icon type="kommentar" :disabled="!kommentarSupplementaryCount" />
      <p class="label">補巻</p>
      <span v-if="kommentarSupplementaryCount" class="count">{{ kommentarSupplementaryCount }}</span>
    </li>
    <li class="countItem" :class="{ '-disabled': !bookCount }" @click="bookCount !== null && $emit('click', 'book')">
      <document-type-icon type="book" :disabled="!bookCount" />
      <p class="label">関連書籍</p>
      <span v-if="bookCount" class="count">{{ bookCount }}</span>
    </li>
    <li class="countItem" :class="{ '-disabled': !lawCount }" @click="lawCount !== null && $emit('click', 'law')">
      <document-type-icon type="law" :disabled="!lawCount" />
      <p class="label">関連法令</p>
      <span v-if="lawCount" class="count">{{ lawCount }}</span>
    </li>
    <li
      class="countItem"
      :class="{ '-disabled': !publicCommentCount }"
      @click="publicCommentCount !== null && $emit('click', 'publicComment')"
    >
      <document-type-icon type="pubcom" :disabled="!publicCommentCount" />
      <p class="label">パブコメ</p>
      <span v-if="publicCommentCount" class="count">{{ publicCommentCount }}</span>
    </li>
    <li
      class="countItem"
      :class="{ '-disabled': !guidelineCount }"
      @click="guidelineCount !== null && $emit('click', 'guideline')"
    >
      <document-type-icon type="guideline" :disabled="!guidelineCount" />
      <p class="label">ガイドライン</p>
      <span v-if="guidelineCount" class="count">{{ guidelineCount }}</span>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import DocumentTypeIcon from '@/components/document-type-icon.vue';
import { CitationCounts } from 'wklr-backend-sdk/models';

@Component({ components: { DocumentTypeIcon } })
export default class ReferenceCountPanel extends Vue {
  @Prop() citationCounts!: CitationCounts | null;
  @Prop() showKommentarSupplementary!: boolean;

  get bookCount(): string | null {
    if (this.citationCounts && this.citationCounts.book > 0) {
      const count = this.citationCounts.book + (this.citationCounts.kommentar || 0);
      return count > 99 ? '99+' : count.toString();
    } else {
      return null;
    }
  }

  get lawCount(): string | null {
    if (this.citationCounts && this.citationCounts.law > 0) {
      return this.citationCounts.law > 99 ? '99+' : this.citationCounts.law.toString();
    } else {
      return null;
    }
  }

  get guidelineCount(): string | null {
    if (this.citationCounts && this.citationCounts.guideline > 0) {
      return this.citationCounts.guideline > 99 ? '99+' : this.citationCounts.guideline.toString();
    } else {
      return null;
    }
  }

  get publicCommentCount(): string | null {
    if (this.citationCounts && this.citationCounts.publicComment > 0) {
      return this.citationCounts.publicComment > 99 ? '99+' : this.citationCounts.publicComment.toString();
    } else {
      return null;
    }
  }

  get kommentarSupplementaryCount(): string | null {
    if (
      this.citationCounts &&
      this.citationCounts.kommentarSupplementary &&
      this.citationCounts.kommentarSupplementary > 0
    ) {
      return this.citationCounts.kommentarSupplementary > 99
        ? '99+'
        : this.citationCounts.kommentarSupplementary.toString();
    } else {
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.reference-count-panel {
  position: absolute;
  right: 20px;
  top: 40px;
  background: rgba(255, 255, 255, 0.5);
  padding: 0;

  > .countItem {
    list-style: none;
    border: 1px solid #e0e0e0;
    width: 65px;
    height: 65px;
    text-align: center;
    position: relative;
    margin-top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    > .label {
      font-size: 11px;
      margin: 0;

      transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    > .count {
      position: absolute;
      top: 4px;
      right: 4px;
      background: red;
      border-radius: 50%;
      color: white;
      width: 2em;
      height: 2em;
      line-height: 2em;
      font-size: 10px;
    }

    &.-disabled {
      cursor: auto;

      > .label {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}
</style>
