import { escapeRegExp } from '@/utility';
import { BookCitations, KommentarCitations, PurchaseTypeEnum } from 'wklr-backend-sdk/models';
import { CitationsByDocumentTypeBase } from '@/types/Citations';

export type MappedBookCitations = CitationsByDocumentTypeBase<
  BookCitations,
  'title' | 'publisher' | 'thumbnailURI' | 'isbn' | 'packs'
> & {
  author: string;
  publishedYear: number;
  purchaseType?: PurchaseTypeEnum;
  docAccessible?: boolean;
  purchased?: boolean;
  availableFrom?: Date;
};

export type MappedKommentarCitations = CitationsByDocumentTypeBase<
  KommentarCitations,
  'title' | 'publisher' | 'thumbnailURI' | 'isbn' | 'packs'
> & {
  author: string;
  publishedYear: number;
  purchaseType?: PurchaseTypeEnum;
  docAccessible?: boolean;
};

const CONTINUOUS_MARKER = '......';
const WINDOW_LENGTH = 200;

type CommonContentType = {
  content: string;
  key: number;
  series: number;
  label: string;
  linkText?: string;
};

/** linkText を含む <a> タグを <b> タグに置き換えて、それ以外のタグを消去する */
const sanitizeTags = (content: string, linkText?: string): { content: string; tagStringLength: number } => {
  let tagStringLength = 0;
  if (linkText) {
    const REPLACE_LENGTH = '<b></b>'.length;
    const regexp = new RegExp(`<a.*?>${escapeRegExp(linkText)}</a>`, 'ig');
    tagStringLength = (content.match(regexp) || []).length * REPLACE_LENGTH;
    content = content.replace(regexp, `<b>${linkText}</b>`);
  }
  content = content.replace(/<\/?(\w+?).*?>/gi, (str: string, tag: string): string => (tag === 'b' ? str : ''));
  return { content, tagStringLength };
};

/**
 * position を中心に前後 length 文字数を抽出する
 * TODO: マッチする位置によって長さが変わってしまうが良いかどうかを確認する
 */
const extractSentence = (content: string, position: number, length: number): string => {
  let start = position - Math.floor(length / 2);
  let end = position + Math.ceil(length / 2);

  let sentence = '';
  if (start < 0) {
    start = 0;
  } else {
    sentence = CONTINUOUS_MARKER;
  }
  if (end > content.length) {
    end = content.length;
    sentence += content.slice(start, end);
  } else {
    sentence += content.slice(start, end);
    sentence += CONTINUOUS_MARKER;
  }

  return sentence;
};

/**
 * linkText がある場合はそこを中心に、ない場合は先頭から文字列を抜き出す
 * <b></b> 分文字列長を長くする
 */
const generateSentence = (content: string, linkText?: string): string => {
  const { content: sentence, tagStringLength } = sanitizeTags(content, linkText);
  const position = linkText ? sentence.indexOf(linkText) : 0;
  return extractSentence(sentence, position, WINDOW_LENGTH + tagStringLength);
};

type BuildReferencingBooks = {
  (books: BookCitations[]): MappedBookCitations[];
  (kommentarBooks: KommentarCitations[]): MappedKommentarCitations[];
};

/** 関連書籍の表示用オブジェクトを生成する */
export const buildReferencingBooks: BuildReferencingBooks = (books) => {
  return books.map((book) => {
    const citations =
      book.contents === null
        ? null
        : (book.contents as CommonContentType[]).map((content) => {
            return {
              key: content.key,
              series: content.series,
              label: content.label,
              sentence: generateSentence(content.content, content.linkText),
            };
          });
    const year = new Date(book.publishedOn === null ? 0 : book.publishedOn).getFullYear();
    return {
      docId: book.id,
      isbn: book.isbn,
      title: book.title,
      author: book.authors.join(', '), // TODO: これで良いのか？
      publisher: book.publisher,
      publishedYear: year,
      purchaseType: book.purchaseType || undefined,
      thumbnailURI: book.thumbnailURI,
      citations,
      purchased: 'purchased' in book ? book.purchased : undefined,
      availableFrom: 'availableFrom' in book ? new Date(book.availableFrom ?? '') : undefined,
      packs: book.packs,
      docAccessible: book.docAccessible,
    };
  });
};

export const hasCitation = (book: MappedBookCitations | MappedKommentarCitations): boolean => {
  return !!book.citations && !!book.citations.length;
};
