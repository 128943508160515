<template>
  <ol id="toc-root" ref="tocRoot" class="toc-root" :class="`font-size-${fontSize}`">
    <template v-for="volumeToc in allVolumesToc">
      <li
        :key="volumeToc.docTitle"
        class="volume-title"
        :aria-expanded="volumesOpen.includes(volumeToc.docId) || currentRecord.id === volumeToc.docId"
        :title="
          volumeToc.docId === currentRecord.id
            ? undefined
            : volumesOpen.includes(volumeToc.docId)
            ? '目次を閉じる'
            : '目次を開く'
        "
        @click.stop="toggleVolumeOpen(volumeToc.docId)"
      >
        <span
          :class="{
            '-show': volumeToc.totalSearchHits > 0,
            '-open': volumesOpen.includes(volumeToc.docId) || currentRecord.id === volumeToc.docId,
          }"
          class="counter"
        >
          {{ volumeToc.totalSearchHits }}
        </span>
        <p class="ma-0 titleText">
          <strong class="mr-1">
            {{ volumeToc.volumeInfo.volumeNumber }}
            <small v-if="volumeToc.volumeInfo.volumeNumberSuffix">
              {{ volumeToc.volumeInfo.volumeNumberSuffix }}
            </small>
          </strong>
          {{ volumeToc.volumeInfo.volumeTitle }}
          <small v-if="volumeToc.volumeInfo.rangeDescription">
            <br />
            {{ volumeToc.volumeInfo.rangeDescription }}
          </small>
        </p>
        <wklr-icon-button
          v-if="volumeToc.docId !== currentRecord.id"
          type="chevron"
          :dense="true"
          :direction="volumesOpen.includes(volumeToc.docId) ? 'down' : 'right'"
        />
      </li>
      <template v-if="currentRecord.id === volumeToc.docId">
        <!-- 閲覧中の巻 -->
        <toc-v2-node
          v-for="tocKey in volumeToc.rootNodeKeys"
          :key="tocReferenceKey(volumeToc.docId, tocKey)"
          :doc-id="volumeToc.docId"
          :hide-page-seq="volumeToc.isSeqUnique || volumeToc.isLaw || activeViewer === 'pdf'"
          v-bind="{ activeViewer, currentRecord, focusedNode, tocKey }"
          @visitSection="$emit('visitSection', $event)"
        />
      </template>
      <template v-else-if="volumesOpen.includes(volumeToc.docId)">
        <!-- 他の巻（開いている場合） -->
        <toc-v2-node
          v-for="tocKey in volumeToc.rootNodeKeys"
          :key="tocReferenceKey(volumeToc.docId, tocKey)"
          :doc-id="volumeToc.docId"
          :hide-page-seq="true"
          :focused-node="null"
          v-bind="{ activeViewer, currentRecord, tocKey }"
          @visitSection="$emit('visitSection', $event)"
        />
      </template>
    </template>
  </ol>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import TocV2Node from '@/components/toc-panel/toc-v2-node.vue';
import { Viewer } from '@/types/Viewer';
import { Nullable } from '@/types/nullable';
import { AllVolumesToc, DocRecordExtended, tocReferenceKey } from '@/utils/tocUtils';
import WklrIconButton from '@/components/shared/wklr-icon-btn.vue';

@Component({ components: { TocV2Node, WklrIconButton } })
export default class AllVolumesTocNodesPanel extends Vue {
  tocReferenceKey = tocReferenceKey;

  @Prop() currentRecord!: DocRecordExtended & Required<Pick<DocRecordExtended, 'allVolumesToc'>>;
  @Prop() activeViewer!: Viewer;
  @Prop() allVolumesToc!: AllVolumesToc;
  @Prop() inViewSections!: number[];
  @Prop() inViewSectionsWithAncestors!: number[];
  @Prop() focusedNode!: Nullable<number>;
  @Prop() fontSize!: '14' | '18' | '24' | '34';

  volumesOpen: string[] = [];

  mounted() {
    // デフォルトでは現在閲覧中の巻以外は畳んでおく
    this.volumesOpen = [this.currentRecord.id];
  }

  toggleVolumeOpen(docId: string) {
    if (docId === this.currentRecord.id) return;
    if (this.volumesOpen.includes(docId)) {
      this.volumesOpen = this.volumesOpen.filter((id) => id !== docId);
    } else {
      this.volumesOpen.push(docId);
    }
  }
}
</script>

<style lang="scss" scoped>
.volume-title {
  display: flex;
  padding: 4px 8px;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;

  > .counter {
    flex-basis: 0;
    text-align: center;
    position: relative;
    border: 1px solid transparent;
    border-radius: 2em;
    background: white;
    margin-top: 1px;
    margin-right: -2px;
    margin-left: -2px;
    width: 28px;
    height: 28px;
    color: #22c;
    line-height: 26px;
    opacity: 0;
    transition-property: opacity, flex-basis;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;

    &::after {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      display: block;
      border: 1px solid transparent;
      border-radius: 2em;
      content: '';
    }

    &.-show {
      opacity: 1;
      border-color: #22c;
      flex-basis: 28px;

      &::after {
        border-color: #88c;
      }
    }

    &.-open {
      opacity: 0.5;
    }
  }

  > .titleText {
    flex-grow: 2;
    padding-left: 6px;
  }
}
</style>
