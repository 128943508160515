<template>
  <div>
    <p v-if="isReserved">このコンテンツは予約済みです。{{ reservedMessage }}</p>
    <p v-else-if="entry.purchaseType === PurchaseTypeEnum.Purchasable">
      このコンテンツはStoreでの購入後にアクセスできるようになります <br />
      <a :href="getBookLink(entry.docId || '')">コンテンツ購入画面へ</a>
    </p>
    <p v-else-if="promotionForKommentar">この文書の本文は閲覧できません<br />別プランのご契約にてご覧いただけます</p>
    <p v-else-if="entry.packs && entry.packs.length">
      この文書の本文は閲覧できません<br />{{ entry.packs[0].name }}などのご契約にてご覧いただけます
    </p>
    <p v-else-if="purchaseLink">
      この文書の本文は閲覧できません<br /><a :href="purchaseLink" target="_blank" @click="onClickPurchaseLink">{{
        purchaseLinkLabel
      }}</a
      >などからご購入いただけます。
    </p>
    <p v-else>この文書の本文は閲覧できません</p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Pack, WithReferenceContents } from 'wklr-backend-sdk/models';
import { PurchaseTypeEnum } from 'wklr-backend-sdk/models';
import { getShopifyMultipassAuthUrl, shopifyDocDetailsPath } from '@/utils/shopifyUtils';
import dayjs from 'dayjs';
import { isReserved } from '@/utils/documentUtils';

@Component
export default class CitationEntryUnavailable extends Vue {
  @Prop()
  entry!: {
    isbn?: string;
    packs: Pack[];
    purchaseType?: PurchaseTypeEnum;
    docId?: string;
    purchased?: boolean;
    availableFrom?: Date;
  } & WithReferenceContents;

  @Prop()
  promotionForKommentar?: boolean;

  PurchaseTypeEnum = PurchaseTypeEnum;

  get purchaseLink(): string | null {
    if (this.entry.isbn) {
      return `https://store.legalscape.jp/p/${this.entry.isbn}`;
    } else {
      return null;
    }
  }

  get purchaseLinkLabel(): string {
    return 'Legalscape Store';
  }

  getBookLink(docId: string): string {
    return getShopifyMultipassAuthUrl(shopifyDocDetailsPath(docId));
  }

  onClickPurchaseLink() {
    if (this.entry.isbn) {
      this.$telemetry.sendPurchaseLinkTelemetry({ isbn: this.entry.isbn }, this.$route);
    }
  }

  get isReserved(): boolean {
    return isReserved(this.entry);
  }

  get reservedMessage(): string {
    const availableFrom = this.entry.availableFrom;
    if (!availableFrom) return '';
    const formattedDate = dayjs(availableFrom).format('YYYY年MM月DD日HH時mm分');
    return `${formattedDate}以降ご利用いただけます`;
  }
}
</script>
