import { Route } from 'vue-router';
import { DocRecord } from 'wklr-backend-sdk/models';
import { Nullable } from '@/types/nullable';
import { Viewer } from '@/types/Viewer';
import { isAccessible } from './documentUtils';
import { PageDocumentHash } from './urlUtils';

/**
 * 表示位置からページ番号を取得する
 */
export const getPageFromInviewInfo = (currentInviewInfo: PageDocumentHash | undefined, record: DocRecord): number => {
  // 位置情報が取得できない場合、文献の先頭を返す
  if (!currentInviewInfo) return 1;
  if (currentInviewInfo.type === 'pdf') {
    return currentInviewInfo.page;
  }
  return getPageFromKey(currentInviewInfo.key, record) || 1;
};

// hashParam() で利用する定義たち
const DocumentHashParamsStringKeys = ['folio'] as const;
type DocumentHashParamsStringKeys = (typeof DocumentHashParamsStringKeys)[number];
const DocumentHashParamsNumberKeys = ['page', 'key', 'series'] as const;
type DocumentHashParamsNumberKeys = (typeof DocumentHashParamsNumberKeys)[number];

const isStringKey = (
  key: DocumentHashParamsStringKeys | DocumentHashParamsNumberKeys,
): key is DocumentHashParamsStringKeys => DocumentHashParamsStringKeys.includes(key as DocumentHashParamsStringKeys);

/**
 * ハッシュ文字列から key に対応する値を key ごとに String もしくは Number にパースして取り出す
 *
 * TODO: searchParams に合わせたフォーマットにしているが実態はハッシュなので URLSearchParams は使っていない。要検討
 *
 * @private
 * @param hash URL から抽出したハッシュ文字列
 * @param key ハッシュから取り出すパラメータ名
 * @returns key に対応する値。ハッシュに含まれていない場合 null が返る
 */
const hashParam: {
  (hash: string, key: DocumentHashParamsStringKeys): Nullable<string>;
  (hash: string, key: DocumentHashParamsNumberKeys): Nullable<number>;
} = (hash: string, key: DocumentHashParamsStringKeys | DocumentHashParamsNumberKeys): any => {
  if (isStringKey(key)) {
    const match = hash.match(new RegExp(String.raw`\b${key}=([^&]+)`));
    if (!match) return null;
    return decodeURIComponent(match[1]);
  } else {
    const match = hash.match(new RegExp(String.raw`\b${key}=(\d+)`));
    if (!match) return null;
    return Number.parseInt(match[1], 10);
  }
};

// FIXME: -document.asyncData() を修正して Nullable ではないようにしたい
const getViewer = (route: Route, record: DocRecord): Nullable<Viewer> => {
  if (!isAccessible(record)) return null;
  if (route.query['view'] === 'pdf') {
    if (record.pdfFileURI) {
      return 'pdf';
    } else {
      return 'web';
    }
  }
  if (record.isWebViewAvailable) return 'web';
  if (record.pdfFileURI) return 'pdf';
  return null;
};

/**
 * 表示位置から page, key, series を取得する
 * asyncData で利用する想定なので initial の prefix をつけている
 * FIXME: そもそも、これが不要な実装にしたい
 * @param currentInviewInfo
 */
export const getInitials = (
  currentInviewInfo?: PageDocumentHash,
): { initialPage: Nullable<number>; initialKey: Nullable<number>; initialSeries: Nullable<number> } => {
  if (!currentInviewInfo) return { initialPage: null, initialKey: null, initialSeries: null };
  switch (currentInviewInfo.type) {
    case 'web':
      return { initialPage: null, initialKey: currentInviewInfo.key, initialSeries: currentInviewInfo.series };
    case 'pdf':
      return { initialPage: currentInviewInfo.page, initialKey: null, initialSeries: null };
  }
};

/**
 * ドキュメントの folioPerSeq を参照して folio に対応するページ番号を取得する
 * @private
 * @param folio folioPerSeq に対応として含まれている目次文字列
 * @param record DocRecord
 * @returns folio に対応する page 番号（ 1-indexed ）
 */
const getPageFromFolio = (folio: Nullable<string>, record: DocRecord): Nullable<number> => {
  if (folio === null) return null;
  if ('folioPerSeq' in record && record.folioPerSeq !== null) {
    const foundSeq = record.folioPerSeq.indexOf(folio);
    if (foundSeq !== -1) {
      return foundSeq + 1; // folioPerSeq は 0-indexed なので 1-indexed 変換する
    }
  }
  return null;
};

/**
 * ドキュメントの toc を参照して key に対応するページ番号を取得する
 * @private
 * @param key
 * @param record DocRecord
 * @returns key に対応する page 番号（ 1-indexed ）
 */
const getPageFromKey = (key: Nullable<number>, record: DocRecord): Nullable<number> => {
  if (key === null) return null;
  if (record.toc !== null) {
    const tocForKey = record.toc.byKey.find((toc) => toc.key >= key);
    if (tocForKey) return tocForKey.pageSeq + 1; // pageSeq は 0-indexed, page は 1-indexed なので変換する
  }
  return null;
};

/**
 * ドキュメントの toc を参照してページ番号に対応する key, series を取得する
 * @private
 * @param page 1-indexed
 * @param record DocRecord
 * @returns page に対応する key, series
 */
const getKeyAndSeriesFromPage = (
  page: Nullable<number>,
  record: DocRecord,
): Nullable<{ key: number; series: Nullable<number> }> => {
  if (page === null) return null;
  const pageZeroIndexed = page - 1; // page は 1-indexed, pageSeq は 0-indexed なので変換する
  if (record.toc !== null) {
    const tocForPage = record.toc.byKey.find((toc) => toc.pageSeq >= pageZeroIndexed);
    if (tocForPage) return { key: tocForPage.key, series: null };
  }
  return null;
};

/**
 * ドキュメントの folioPerSeq, toc を参照してページ番号に対応する key, series を取得する
 * @private
 * @param folio folioPerSeq に対応として含まれている目次文字列
 * @param record DocRecord
 * @returns page に対応する key, series
 */
const getKeyAndSeriesFromFolio = (
  folio: Nullable<string>,
  record: DocRecord,
): Nullable<{ key: number; series: Nullable<number> }> => {
  if (folio === null) return null;
  return getKeyAndSeriesFromPage(getPageFromFolio(folio, record), record);
};

/**
 * ルート情報と文献情報から、表示するべき viewer と表示位置を計算する
 */
export const getDocumentPositionFromRoute = (route: Route, record: DocRecord) => {
  const initialPage = hashParam(route.hash, 'page');
  const initialPageFolio = hashParam(route.hash, 'folio');
  const initialKey = hashParam(route.hash, 'key');
  const initialSeries = hashParam(route.hash, 'series');
  const viewer = getViewer(route, record);

  const emphasized = initialPage !== null || initialKey !== null || initialSeries !== null;

  let currentInviewInfo: PageDocumentHash | undefined = undefined;
  switch (viewer) {
    case 'pdf':
      currentInviewInfo = {
        type: 'pdf',
        page: initialPage || getPageFromFolio(initialPageFolio, record) || getPageFromKey(initialKey, record) || 1,
      };
      break;
    case 'web':
      currentInviewInfo = {
        type: 'web',
        ...(initialKey !== null
          ? { key: initialKey, series: initialSeries }
          : getKeyAndSeriesFromPage(initialPage, record) ||
            getKeyAndSeriesFromFolio(initialPageFolio, record) || { key: 0, series: null }),
      };
      break;
  }

  return {
    currentInviewInfo,
    viewer,
    emphasized,
  };
};
