import { Context } from '@nuxt/types';
import { Component, Vue, State, Watch } from 'nuxt-property-decorator';
import PdfViewer from '@/components/viewer/pdf-viewer.vue';
import WebViewer from '@/components/viewer/web-viewer.vue';
import AttachmentViewer from '@/components/viewer/attachment-viewer.vue';
import ErrorViewer from '@/components/viewer/error-viewer.vue';
import NoneViewer from '@/components/viewer/none-viewer.vue';
import CitationPanel, { Tabs } from '@/components/citation-panel.vue';
import TocPanel from '@/components/toc-panel.vue';
import LayoutResizableDrawer from '@/components/base/layout-resizable-drawer.vue';
import ReferenceCountPanel from '@/components/legalweb/reference-count-panel.vue';
import { PDFPageIdentifier } from '@/types/pdf-page-identifier';
import { TocInteractive, Viewer } from '@/types/Viewer';
import { OutlineItem } from '@/types/pdfjs';
import { Route } from 'vue-router';
import rison from 'rison';
import { State as MyState, FindState } from '@/store';
import { Nullable } from '@/types/nullable';
import {
  accumulateTotalHitsByVolumes,
  mapLookupResultToDocumentHits,
  mapPdfPageHitsToDocumentHits,
} from '@/utils/lookupUtils';
import { NonPermitDocumentError } from '@/utils/errorUtils';
import {
  getPageDocumentHashFromUrl,
  PageDocumentHash,
  replaceHashAndViewerType,
  replaceViewerType,
  generateReferenceDocInfoFromRoute,
  replaceQuery,
} from '@/utils/urlUtils';
import { arraysEqual, mode } from '@/utility';
import { sectionKeysWithAncestorsAndDescendants } from '@/utils/webViewerUtils';
import { PartialSearchQuery } from '@/types/SearchQuery';
import { Attachment, CitationCounts, Toc, TocNode } from 'wklr-backend-sdk/models';
import { getAcquisitionType, getAvailableViewers, isAccessible } from '@/utils/documentUtils';
import { EventUpdatePageMatch } from 'pdfjs-dist/web/pdf_viewer';
import { getDocumentPositionFromRoute, getPageFromInviewInfo, getInitials } from '@/utils/documentPositionUtils';
import { DocRecordExtended, UnaccessibleDocRecordExtended } from '@/utils/tocUtils';

type AsyncData = Pick<
  Document,
  | 'allCitationCounts'
  | 'currentInviewInfo'
  | 'emphasized'
  | 'record'
  | 'isPermissionError'
  | 'initialPage'
  | 'initialKey'
  | 'initialSeries'
  | 'searchQuery'
  | 'referenceDocId'
  | 'referenceKey'
  | 'referenceSeries'
  | 'displayedAttachment'
>;

/** pdf の dest は 0-index だが pdf-viewer のページ指定は 1-index なので利用時に +1 して利用すること */
export type PdfNavigationArgs = { type: 'pdf'; dest: OutlineItem['dest'] | number };

export type WebNavigationArgs = { type: 'web'; key: number; series: Nullable<number>; flash?: boolean };

type VisitSectionArgs = PdfNavigationArgs | WebNavigationArgs;

/** コンメンタールの逆引きでは画面上では書籍に含めるため、それを示した型定義 */
export type AggregatedCitationCount = Omit<CitationCounts, 'kommentar'>;
@Component({
  layout: 'document',
  components: {
    LayoutResizableDrawer,
    PdfViewer,
    WebViewer,
    AttachmentViewer,
    ErrorViewer,
    NoneViewer,
    TocPanel,
    CitationPanel,
    ReferenceCountPanel,
  },
})
export default class Document extends Vue {
  $refs!: {
    layout: LayoutResizableDrawer;
    pdfViewer: PdfViewer;
    webViewer: WebViewer;
    attachmentViewer: AttachmentViewer;
    tocPanel: TocPanel;
    citationPanel: CitationPanel;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;

  // TODO: これが渡されている各コンポーネントの型が正しい確認する
  record!: DocRecordExtended | UnaccessibleDocRecordExtended;

  /** 文献の閲覧権限が無い場合はエラー（true）になる */
  isPermissionError!: boolean;

  /** ロード時に表示するページ (1-index) */
  initialPage: Nullable<number> = null;

  initialKey: Nullable<number> = null;

  initialSeries: Nullable<number> = null;

  displayedAttachment: Attachment | null = null;

  // initialKey の部分を強調表示するか
  emphasized = false;

  /** セッションを一意に識別する文字列（閲覧したページを次々追記するため） */
  eventId = 'lsevt-' + Date.now();

  /** 現在の inview 情報 */
  currentInviewInfo?: PageDocumentHash;

  referenceDocId = '';
  referenceKey: number | null = null;
  referenceSeries: number | null = null;

  allCitationCounts: { [key: string]: AggregatedCitationCount } = {};
  citationCounts: Omit<AggregatedCitationCount, 'kommentar'> | null = null;
  isRightDrawerOpen = false; // refs の中の value はリアクティブにならないのでここでも持つ。もしかしたら model 的に渡すようにするほうがいいのかもしれない

  get showCitationCountPanel(): boolean {
    if (this.isRightDrawerOpen) return false;
    if (this.showKommentarSupplementary) return true;
    if (Object.keys(this.allCitationCounts).length > 0) return true;
    return false;
  }

  // TODO: このあたりの citation-panel 周りを適切な位置に移動する
  // sectionkeyは現在見ているセクションを示すが、関連文献を表示する際にはその子セクションも対象にしたい
  get sectionKeysWithAncestorsAndDescendants(): number[] {
    if (this.sectionKeyToSearchCitationDocuments === undefined || !this.record.toc) {
      return [];
    }

    return sectionKeysWithAncestorsAndDescendants(this.record.toc, this.sectionKeyToSearchCitationDocuments);
  }

  get sectionTitle(): string {
    if (
      this.record.toc &&
      this.record.toc.byKey &&
      this.sectionKeyToSearchCitationDocuments &&
      this.record.toc.byKey[this.sectionKeyToSearchCitationDocuments]
    ) {
      return this.record.toc.byKey[this.sectionKeyToSearchCitationDocuments].label;
    }
    return '';
  }

  get showKommentarSupplementary(): boolean {
    return Object.values(this.allCitationCounts).some((counts) => counts.kommentarSupplementary !== undefined);
  }

  @Watch('documentId', { immediate: true })
  @Watch('sectionKeysWithAncestorsAndDescendants', { immediate: true })
  updateDocumentId() {
    if (!(this.record.id && this.sectionKeysWithAncestorsAndDescendants.length > 0)) {
      return;
    }

    try {
      this.citationCounts = this.sectionKeysWithAncestorsAndDescendants
        .map((key) => this.allCitationCounts[key])
        .filter((x): x is CitationCounts => x !== undefined)
        .map((x) => ({ ...x, kommentar: x.kommentar || 0, kommentarSupplementary: x.kommentarSupplementary || 0 }))
        .reduce(
          (acc, counts) => ({
            book: acc.book + counts.book,
            law: acc.law + counts.law,
            guideline: acc.guideline + counts.guideline,
            publicComment: acc.publicComment + counts.publicComment,
            kommentar: acc.kommentar + counts.kommentar,
            kommentarSupplementary: acc.kommentarSupplementary + counts.kommentarSupplementary,
          }),
          {
            book: 0,
            law: 0,
            guideline: 0,
            publicComment: 0,
            kommentar: 0,
            kommentarSupplementary: 0,
          },
        );
    } catch (error) {
      this.citationCounts = null;
      this.$refs.citationPanel.setTab('error');
      console.log(error);
    }
  }

  rightDrawerOpenHandler(): void {
    this.isRightDrawerOpen = true;
  }

  rightDrawerCloseHandler(): void {
    this.isRightDrawerOpen = false;
  }

  referenceClickHandler(tab: Tabs): void {
    if (!this.isRightDrawerOpen) {
      this.$refs.layout.toggleRightDrawer();
    }
    this.$refs.citationPanel.setTab(tab);
  }

  @Watch('currentInviewInfo', { immediate: true })
  updateLocationHash(args: PageDocumentHash | undefined): void {
    if (args === undefined) return;

    const url = replaceHashAndViewerType(location.href, args);
    history.replaceState({}, document.title, url);
  }

  /** 現在利用中のビューアー */
  @State((state: MyState) => state.document.activeViewer) activeViewerState!: Viewer;

  /** 現在表示中のTOC */
  @State((state: MyState) => state.document.toc) currentToc!: Record<string, TocInteractive>;

  get activeViewer(): Viewer {
    return this.activeViewerState;
  }

  set activeViewer(viewer: Viewer) {
    if (this.activeViewerState !== viewer) {
      this.$store.commit('setActiveViewer', viewer);
    }
  }

  /** Webビュー：このlifecycleの中で一度でも利用したかどうか */
  get webViewerRendered(): boolean {
    return this.activeViewer === 'web';
  }

  /** PDFビュー：このlifecycleの中で一度でも利用したかどうか */
  get pdfViewerRendered(): boolean {
    return this.activeViewer === 'pdf';
  }

  /** PDFのoutline */
  outline: OutlineItem[] | null = null;

  searchQuery?: PartialSearchQuery;

  /** 読んだページ (FIXME: 履歴の取り方を統一する AB#226) */
  seenPages: number[] = [];

  /** in-viewなセクションのリスト（差分を取るため） */
  previousInViewSections: number[] = [];

  /** 本文内検索用データ */
  @State((state) => state.document.find) find!: FindState;

  /** 個人的な機能を無効化する */
  get disablePersonalFeature(): boolean {
    return this.$domain.isMHMMypage || this.$domain.isSHKommentar || this.$domain.isSHKommentarLibrary;
  }

  get isEnableRightDrawer(): boolean {
    if (this.activeViewer !== 'web') return false;
    if (this.displayedAttachment !== null) return false;
    if (this.isPermissionError) return false;
    if (!this.$auth.permissions.legalweb) return false;
    return true;
  }

  get availableViewers(): Viewer[] {
    return getAvailableViewers(this.record);
  }

  listSelectedPages(): ReturnType<PdfViewer['listSelectedPages']> | null {
    if (this.activeViewer === 'pdf') {
      return this.$refs.pdfViewer.listSelectedPages();
    } else {
      return null;
    }
  }

  head(): { title: string } {
    return {
      title: `${this.record.title}`,
    };
  }

  async asyncData({
    route,
    params: { id },
    query,
    store,
    redirect,
    app: { $repositories, $auth, $telemetry },
  }: Context): Promise<AsyncData | undefined> {
    const docResult = await (async (): Promise<
      | {
          record: DocRecordExtended | UnaccessibleDocRecordExtended;
          isPermissionError: boolean;
        }
      | undefined
    > => {
      try {
        const record = await $repositories.docs.getExtended(id);
        return { record, isPermissionError: false };
      } catch (err) {
        if (err instanceof NonPermitDocumentError) {
          // personal のときリダイレクトする
          if ($auth.user.organization.oid === 'personal') {
            const account = await $repositories.accounts.getAccount();
            const nonPersonal = account.organizations.filter((org) => {
              return org.oid !== 'personal';
            });
            // TODO: これは読む権限のあるドメインにリダイレクトするのが理想
            if (nonPersonal.length) {
              redirect('https://' + nonPersonal[0].subdomain! + '.legalscape.jp' + route.path);
              return;
            }
          }

          return { record: err.record as UnaccessibleDocRecordExtended, isPermissionError: true };
        }
        throw err;
      }
    })();

    if (docResult === undefined) return;

    // FIXME: ErrorBoundary を導入することで、ここ以降の record を AccessibleDocRecordTiny として取り扱いたい（ それ以外の場合はここ以降を通らないようにしたい）

    const allCitationCounts = $auth.permissions.legalweb
      ? await $repositories.citations.getAllCitationCount(id).catch((error) => {
          if (error.response && error.response.status === 404) {
            // RDB に文献のレコードが存在しない場合に 404 が返却されるが、閲覧に不都合が生じるのでそのようなケースをここで握りつぶす
            // FIXME: LS-1282
            console.warn('Ignore 404: ', error);
            $telemetry.sendErrorTelemetry(error, route);
            return {};
          }

          throw error;
        })
      : {};

    const { record, isPermissionError } = docResult;
    if (record.toc) {
      Object.freeze(record.toc.byKey);
      record.toc.byKey.forEach((t: TocNode) => Object.freeze(t));
    }

    const { currentInviewInfo, viewer, emphasized } = getDocumentPositionFromRoute(route, record);

    store.commit('setActiveViewer', viewer);

    let displayedAttachment: Attachment | null = null;
    if (route.query.attachmentId) {
      const attachmentId = Array.isArray(route.query.attachmentId)
        ? route.query.attachmentId[0] || ''
        : route.query.attachmentId;
      displayedAttachment = await $repositories.attachments.getAttachment(id, attachmentId);
    }

    const { referenceDocId, referenceKey, referenceSeries } = generateReferenceDocInfoFromRoute(route);
    return {
      allCitationCounts,
      currentInviewInfo,
      emphasized,
      record,
      // FIXME: initialKey, initialPage などを currentInviewInfo から取れるようにすれば不要になる
      ...getInitials(currentInviewInfo),
      isPermissionError,
      referenceDocId,
      referenceKey,
      referenceSeries,
      displayedAttachment,
      searchQuery: query.q ? rison.decode_object(<string>query.q) : undefined,
    };
  }

  async fetch({ store, query }: Context): Promise<void> {
    if (query.q) {
      store.commit('updateSearchQuery', rison.decode_object(<string>query.q));
    }
  }

  popStateHandler(_: PopStateEvent): void {
    const pageDocumentHashInfo = getPageDocumentHashFromUrl(location.href);
    if (pageDocumentHashInfo !== null) {
      switch (pageDocumentHashInfo.type) {
        case 'pdf':
          // PDF の場合は iframe の URL を更新して pdf-viewer を再表示させる
          this.initialPage = pageDocumentHashInfo.page;
          this.activeViewer = 'pdf';
          break;
        case 'web':
          if (this.activeViewer === 'web') {
            this.visitSection(pageDocumentHashInfo, false);
          } else {
            this.initialKey = pageDocumentHashInfo.key;
            this.initialSeries = pageDocumentHashInfo.series;
            this.activeViewer = 'web';
          }

          break;
      }
    } else {
      console.error('URLから表示位置を復元できません\nURL: ', location.href);
    }
  }

  created(): void {
    window.addEventListener('popstate', this.popStateHandler);
  }

  mounted(): void {
    this.restoreFindStateFromRouteQuery();

    this.postInitialDocumentHistory();

    this.$telemetry.sendEnvironmentTelemetry(
      {
        UAString: navigator.userAgent,
        screen: {
          width: screen.width,
          height: screen.height,
          windowInnerWidth: window.innerWidth,
          windowInnerHeight: window.innerHeight,
        },
      },
      this.$route,
    );
    this.$telemetry.sendDocumentViewTelemetry(this.$route);
  }

  beforeDestroy(): void {
    window.removeEventListener('popstate', this.popStateHandler);
    this.clearFind();
  }

  /** 同一ページでクエリパラメータが更新されたときのhook */
  beforeRouteUpdate(to: Route, from: Route, next: () => void): void {
    const { referenceDocId, referenceKey, referenceSeries } = generateReferenceDocInfoFromRoute(to);
    this.referenceDocId = referenceDocId;
    this.referenceKey = referenceKey;
    this.referenceSeries = referenceSeries;

    next();
  }

  /** 現在のURLから検索クエリをデコードしstoreに反映させる */
  restoreFindStateFromRouteQuery(): void {
    if (this.record.toc && this.$route.query.q) {
      let keyword = '';
      try {
        const q = rison.decode_object<PartialSearchQuery>(<string>this.$route.query.q);
        keyword = (q.keyword || '').trim();
      } catch (_) {
        /* nop */
      }

      const keywords = keyword.split(/\s+/).filter((keyword, i, all) => keyword && all.indexOf(keyword) === i);
      this.$store.commit('requestUpdateHighlights', keywords);
    } else {
      this.clearFind();
    }
  }

  get sectionKeyToSearchCitationDocuments(): number {
    const isTargetDepth = (k: number): boolean => {
      if (this.record.toc === null) return false;
      if (!isAccessible(this.record)) return false;
      return this.record.toc.byKey[k].depth === this.record.referencingTargetDepth;
    };

    const filtered = this.previousInViewSections.filter(isTargetDepth);
    if (filtered.length) {
      return Math.min(...filtered);
    } else {
      // ない場合はしょうがないのでとりあえず先頭を返す
      return this.previousInViewSections[0];
    }
  }

  // FIXME: 本来これは web-viewer に紐づくはず。画面内に複数の web-viewer がある時とか考慮して修正しないとどこかで沼にハマりそう
  @Watch('find.keywords')
  async onRequestUpdateHighlights(keywords: string[], previousValue: string[]): Promise<void> {
    // 検索キーワードに変化がない場合はなにもしない
    if (arraysEqual(keywords, previousValue)) {
      return;
    }

    // 検索キーワードが変更された場合 location のキーワード部分を変更する
    try {
      const q = this.$route.query.q ? rison.decode_object<PartialSearchQuery>(<string>this.$route.query.q) : {};
      q.keyword = keywords.join(` `);
      history.replaceState({}, document.title, replaceQuery(location.href, q));
    } catch (_) {
      /* nop */
    }

    // 検索キーワードが空の場合は新しく API へのリクエストは不要なので何もしない
    if (keywords.length === 0) {
      return;
    }

    // PDF検索の場合はリクエストしない
    if (this.activeViewer === 'pdf') return;

    const tocList: [string, Toc][] = (() => {
      if (this.record.allVolumesToc !== undefined) {
        return this.record.allVolumesToc.map((toc): [string, Toc] => [toc.docId, toc]);
      }
      if (this.record.toc !== null) {
        return [[this.record.id, this.record.toc] as [string, Toc]];
      }
      return [];
    })();

    this.$telemetry.sendLookupTelemetry({ keywords }, this.$route);

    try {
      const res = await this.$repositories.docs.getHitsByDocumentId(
        tocList.map(([id]) => id),
        keywords,
      );

      const entries = tocList.map(([docId, toc]) => {
        const result = keywords.map((keyword) => ({
          keyword,
          data: res[docId][keyword],
        }));
        return [docId, mapLookupResultToDocumentHits(result, toc)] as const;
      });

      const allVolumesHits = Object.fromEntries(entries);

      // TODO: 検索位置が最初になってしまう。現時点での inView のエリアで一番近いものを current で設定などできると良さそう
      const documentHits = allVolumesHits[this.record.id];

      const accumulatedTotalHitsByVolumes = accumulateTotalHitsByVolumes(res);

      if (documentHits !== undefined) {
        this.$store.commit({ type: 'setHit', documentHits, totalHitsByVolume: accumulatedTotalHitsByVolumes });
      }
      this.$store.commit({ type: 'updateSectionHits', allVolumesHits });
    } catch (error) {
      this.clearFind();
      this.$toast.error('文献内の検索に失敗しました');
      console.error(error);
    }
  }

  /** PDF 画面での検索マッチにアップデートがあった場合 */
  updatePdfPageMatches(event: EventUpdatePageMatch) {
    if (!this.currentToc) return;
    const documentHits = mapPdfPageHitsToDocumentHits(
      this.record.id,
      this.currentToc,
      event.pageMatches,
      event.keywords,
    );
    this.$store.commit({ type: 'updateSectionHits', allVolumesHits: { [this.record.id]: documentHits } });
  }

  clearFind(): void {
    this.$store.commit('clearSectionHits', { docId: this.record.id });
    this.$store.commit('clearFind');
  }

  /**
   * （現状PDFビューで）特定のページが表示された
   * ※ここで表示とは、レンダ完了済みかつ50%以上画面表示がある状態になったこと
   */
  pdfpageview(page: PDFPageIdentifier): void {
    this.postDocumentPageHistory(page.pageNumber);
  }

  /** PDFビューで特定のページがインビューになった */
  pagechanging(page: PDFPageIdentifier): void {
    if (this.activeViewer === 'pdf') {
      this.currentInviewInfo = { type: 'pdf', page: page.pageNumber };
    }
    this.$refs.tocPanel.updateInViewPage(page);
  }

  /** PDFからoutlineがロードされた */
  outlineLoaded(outline: OutlineItem[]): void {
    if (outline.length > 0) {
      this.outline = outline;
    }
  }

  /**
   * in-viewなセクションが変更された
   * @param inViewSections keyの配列
   */
  async updateInViewSections({
    inViewSections,
    inViewSectionsWithAncestors,
    currentInviewInfo,
  }: {
    inViewSections: number[];
    inViewSectionsWithAncestors: number[];
    currentInviewInfo?: { key: number; series: number };
  }): Promise<void> {
    if (currentInviewInfo && this.activeViewer === 'web') {
      this.currentInviewInfo = { type: 'web', ...currentInviewInfo };
    }
    // 差分を取る
    inViewSections.forEach((key) => {
      if (!this.previousInViewSections.includes(key)) {
        // 新規にin-viewになった
        this.postDocumentSectionHistory(key);
      }
    });

    this.previousInViewSections = inViewSectionsWithAncestors.slice();

    this.$refs.tocPanel.updateInViewSections({
      inViewSections,
      inViewSectionsWithAncestors,
    });
  }

  /**
   * ドキュメント表示時の履歴の記録を実行する
   * FIXME: 初回を分けているのは web の履歴が seq を受け付けないからなので、 API 側が修正されたら初回に限らず履歴を送信して良い
   * @param seq 1-index
   */
  private async postInitialDocumentHistory() {
    switch (this.activeViewer) {
      case 'pdf':
        this.postDocumentPageHistory(getPageFromInviewInfo(this.currentInviewInfo, this.record));
        break;
      case 'web':
        // web-viewer は seq を送らず、初回のみ記録する
        this.$repositories.histories.documentBrowsePages(this.record.id, this.eventId, []);
        break;
    }
  }

  /**
   * PDF: 特定のページの閲覧を記録する
   * @param seq 1-index
   */
  private async postDocumentPageHistory(seq: number) {
    if (this.activeViewer !== 'pdf') return;

    // FIXME: 履歴の取り方を統一する AB#226
    if (this.seenPages.length === 0 || this.seenPages[this.seenPages.length - 1] !== seq) {
      this.seenPages.push(seq);
      this.$repositories.histories.documentBrowsePages(this.record.id, this.eventId, this.seenPages);
    }

    const acquisitionType = getAcquisitionType(this.record);

    return this.$telemetry.sendPDFPageViewTelemetry(
      {
        docId: this.record.id,
        seq,
        eventId: this.eventId,
        acquisitionType,
      },
      this.$route,
    );
  }

  /** Web: 特定のセクションの閲覧を記録する */
  private async postDocumentSectionHistory(key: number) {
    if (this.activeViewer !== 'web') return;

    const acquisitionType = getAcquisitionType(this.record);

    return this.$telemetry.sendWebSectionViewTelemetry(
      {
        docId: this.record.id,
        key,
        eventId: this.eventId,
        acquisitionType,
      },
      this.$route,
    );
  }

  changeDocumentBinderItemsHandler(): void {
    this.$refs.tocPanel.updateBinderItems();
  }

  // ページ移動系のメソッドを集約

  /**
   * 表示切替を実行
   * @param viewer 新しいビューアー
   * @param pageOrKey PDFでのページ番号 (0-index) / セクションのkey
   * @param series コンテンツのseries. web-viewer がターゲットの場合のみ有効
   */
  switchViewer(viewer: Viewer): void {
    if (!this.availableViewers.includes(viewer)) return;

    this.$refs.attachmentViewer.close();

    const toc = this.record.toc;
    const inViewSections = this.$refs.tocPanel.inViewSections; // FIXME: 直接参照したくない

    let pageOrKey: number | undefined = undefined;
    // let series: number | undefined = undefined;
    if (this.activeViewer !== viewer && toc !== null) {
      if (viewer === 'pdf') {
        /** Web view: in-viewなセクションの見出しの出現するページを集めて、最頻値を遷移先ページとする */
        const pageNumber = mode([...new Set(inViewSections)].map((key) => toc.byKey[key].pageSeq));

        // observerとのタイミング調整のため1000ミリ秒ほど見合わせる
        if (pageNumber) {
          setTimeout(() => this.$refs.tocPanel.updateInViewPage({ pageNumber: pageNumber + 1 }), 1000); // FIXME: ここ、何してるの？？？
          pageOrKey = pageNumber;
        }

        pageOrKey = pageNumber !== null ? pageNumber : undefined;
      } else {
        // PDF view: 閲覧中のページに登場する最初の見出しから始まるセクションを遷移先セクションとする

        // データチェック
        let previousSeq = -Infinity;
        for (const { pageSeq } of toc.byKey) {
          if (previousSeq > pageSeq) {
            this.$assert(false, `TOC pageSeq is not well sorted (${this.record.id})`, this.$route);
            break;
          }
          previousSeq = pageSeq;
        }

        let cursor = toc.byKey.length - 2;
        while (
          cursor >= 0 &&
          toc.byKey[cursor].pageSeq + 1 >= this.$refs.tocPanel.inViewPage /*  // FIXME: 直接参照したくない */
        ) {
          --cursor;
        }

        inViewSections.splice(0, inViewSections.length);

        pageOrKey = cursor + 1;
      }
    }

    if (pageOrKey === undefined) {
      console.error('対応するページ番号を取得できません');
      pageOrKey = 0;
    }

    if (viewer === 'pdf') {
      this.switchToPdf(pageOrKey + 1, true);
    } else {
      this.switchToWeb(pageOrKey, null, true);
    }
  }

  /**
   * ビューワーと位置を指定してジャンプする
   * @param VisitSectionArgs
   */
  async visitSection(args: VisitSectionArgs, pushState: boolean): Promise<void> {
    this.$refs.attachmentViewer.close();

    if (this.activeViewer === args.type) {
      switch (args.type) {
        case 'web':
          this.webNavigate(args);
          break;
        case 'pdf':
          if (typeof args.dest === 'number') {
            this.pdfNavigate({ type: 'pdf', dest: args.dest + 1 });
          } else {
            this.pdfNavigate(args);
          }
          break;
      }
    } else {
      if (args.type === 'pdf') {
        const { dest } = args;
        if (typeof dest === 'number') {
          this.switchToPdf(dest + 1, pushState);
        } else {
          // ここを通ることはないはず（ pdf の outline から生成された Toc 経由でしか発生しないため常に pdf-viewer が activeViewer となっているはず）
          console.error('オブジェクト形式のPDF遷移はビュワー切り替え時は使用できません');
        }
      } else {
        this.switchToWeb(args.key, args.series || null, pushState);
      }
    }
  }

  async switchToPdf(page: number, pushState: boolean): Promise<void> {
    if (this.activeViewer === 'pdf') return;

    // まず現時点の URL の viewer を変更して history を追加する
    const url = replaceViewerType(location.href, 'pdf');
    history.pushState({}, document.title, url);

    // PDF の場合は iframe の initialPage を利用して初期表示位置を指定する
    if (pushState) {
      this.initialPage = page;
      this.activeViewer = 'pdf';
    }

    // 最後に表示位置の情報をアップデートする
    this.currentInviewInfo = { type: 'pdf', page };

    this.$telemetry.sendToggleViewerTelemetry({ toggleFrom: 'web', toggleTo: 'pdf' }, this.$route);
  }

  async switchToWeb(key: number, series: Nullable<number>, pushState: boolean): Promise<void> {
    if (this.activeViewer === 'web') return;

    // まず現時点の URL の viewer を変更して history を追加する
    if (pushState) {
      const url = replaceViewerType(location.href, 'web');
      history.pushState({}, document.title, url);
    }

    // ビュワーを変更する
    this.activeViewer = 'web';
    await this.$nextTick();
    this.webNavigate({ type: 'web', key, series });

    // 最後に表示位置の情報をアップデートする
    this.currentInviewInfo = { type: 'web', key, series };

    this.$telemetry.sendToggleViewerTelemetry({ toggleFrom: 'pdf', toggleTo: 'web' }, this.$route);
  }

  /** PDFの特定の位置を表示する */
  async pdfNavigate({ dest }: PdfNavigationArgs): Promise<void> {
    // toc-nodeの中のthis.toc.gotosをvisitSectionにすればこれが不要になる
    this.$refs.attachmentViewer.close();

    if (this.pdfViewerRendered) {
      await this.$refs.pdfViewer.navigate(dest);
    }
  }

  /** Webビューで特定のセクションを表示 */
  async webNavigate({ key, series, flash = false }: WebNavigationArgs): Promise<void> {
    // 無効なドキュメントでは ToC をクリックしても遷移させない
    if (!this.record.docAccessible) return;

    if (this.webViewerRendered) {
      await this.$refs.webViewer.visitSection(key, series, { flash });
    }
  }

  async scrollToSection(key: number): Promise<void> {
    this.$refs.attachmentViewer.close();

    switch (this.activeViewer) {
      case 'pdf':
        // this.record.toc が null の場合、そもそも scrollToSection が呼ばれない。 scroll の呼び出し元を追加する場合には修正が必要
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await this.pdfNavigate({ type: 'pdf', dest: this.record.toc!.byKey[key].pageSeq + 1 });
        break;
      case 'web':
        await this.webNavigate({ type: 'web', key, series: null, flash: true });
        break;
    }

    this.$refs.tocPanel.clearKommentarTocFilter();
  }

  openAttachment(attachment: Attachment): void {
    this.displayedAttachment = attachment;
  }
}
