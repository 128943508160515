import { replaceKanjiNumbersToNumeric, replaceZenkakuNumbersToNumeric } from '@/utils/stringUtils';

/**
 * v-autocomplete の filter として利用するためのフィルタ関数
 * 入力文字列の漢数字と全角数字を正規化して、 TocNode と一致しているかどうかをチェックする
 *
 * @param item TocNode
 * @param queryText
 * @param itemText
 */
export const kommentarTocFilter = (_: unknown, queryText: string, itemText: string): boolean => {
  const standardizedQueryText = replaceZenkakuNumbersToNumeric(replaceKanjiNumbersToNumeric(queryText));
  const regexp = new RegExp((standardizedQueryText.match(/\d+/g) || []).join('.+?'));
  return regexp.test(itemText);
};
