import { removeTags } from '@/utility';
import { hasCitation } from '@/utils/citationUtils';
import NO_IMAGE from '@/assets/noimage.png';
import { showInCitationPanelGenerator } from '@/utils/citationPanelUtils';
import CitationEntryUnavailable from '@/components/citation-entry-unavailable.vue';
import { useDomain } from '@/plugins/domain';
import { PurchaseTypeEnum } from 'wklr-backend-sdk/models';
import { getShopifyMultipassAuthUrl, shopifyDocDetailsUrl } from '@/utils/shopifyUtils';
export default defineComponent({
  name: 'CitationPanelBook',
  components: {
    CitationEntryUnavailable: CitationEntryUnavailable
  },
  props: {
    book: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var domain = useDomain();
    var router = useRouter();
    var showInCitationPanel = showInCitationPanelGenerator(router);
    var showKommentarPromotion = computed(function () {
      return domain.isSHKommentar || domain.isSHKommentarLibrary || domain.isKommentarTrial;
    });
    var getBookLink = function getBookLink(book) {
      return !book.docAccessible && book.purchaseType === PurchaseTypeEnum.Purchasable ? getShopifyMultipassAuthUrl(shopifyDocDetailsUrl(book.docId)) : "/document/".concat(book.docId);
    };
    return {
      noImage: NO_IMAGE,
      removeTags: removeTags,
      showInCitationPanel: showInCitationPanel,
      showKommentarPromotion: showKommentarPromotion,
      getBookLink: getBookLink
    };
  },
  methods: {
    hasCitation: hasCitation
  }
});