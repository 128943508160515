var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ol',{ref:"tocRoot",staticClass:"toc-root",class:`font-size-${_vm.fontSize}`,attrs:{"id":"toc-root"}},[_vm._l((_vm.allVolumesToc),function(volumeToc){return [_c('li',{key:volumeToc.docTitle,staticClass:"volume-title",attrs:{"aria-expanded":_vm.volumesOpen.includes(volumeToc.docId) || _vm.currentRecord.id === volumeToc.docId,"title":volumeToc.docId === _vm.currentRecord.id
          ? undefined
          : _vm.volumesOpen.includes(volumeToc.docId)
          ? '目次を閉じる'
          : '目次を開く'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVolumeOpen(volumeToc.docId)}}},[_c('span',{staticClass:"counter",class:{
          '-show': volumeToc.totalSearchHits > 0,
          '-open': _vm.volumesOpen.includes(volumeToc.docId) || _vm.currentRecord.id === volumeToc.docId,
        }},[_vm._v("\n        "+_vm._s(volumeToc.totalSearchHits)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"ma-0 titleText"},[_c('strong',{staticClass:"mr-1"},[_vm._v("\n          "+_vm._s(volumeToc.volumeInfo.volumeNumber)+"\n          "),(volumeToc.volumeInfo.volumeNumberSuffix)?_c('small',[_vm._v("\n            "+_vm._s(volumeToc.volumeInfo.volumeNumberSuffix)+"\n          ")]):_vm._e()]),_vm._v("\n        "+_vm._s(volumeToc.volumeInfo.volumeTitle)+"\n        "),(volumeToc.volumeInfo.rangeDescription)?_c('small',[_c('br'),_vm._v("\n          "+_vm._s(volumeToc.volumeInfo.rangeDescription)+"\n        ")]):_vm._e()]),_vm._v(" "),(volumeToc.docId !== _vm.currentRecord.id)?_c('wklr-icon-button',{attrs:{"type":"chevron","dense":true,"direction":_vm.volumesOpen.includes(volumeToc.docId) ? 'down' : 'right'}}):_vm._e()],1),_vm._v(" "),(_vm.currentRecord.id === volumeToc.docId)?_vm._l((volumeToc.rootNodeKeys),function(tocKey){return _c('toc-v2-node',_vm._b({key:_vm.tocReferenceKey(volumeToc.docId, tocKey),attrs:{"doc-id":volumeToc.docId,"hide-page-seq":volumeToc.isSeqUnique || volumeToc.isLaw || _vm.activeViewer === 'pdf'},on:{"visitSection":function($event){return _vm.$emit('visitSection', $event)}}},'toc-v2-node',{ activeViewer: _vm.activeViewer, currentRecord: _vm.currentRecord, focusedNode: _vm.focusedNode, tocKey },false))}):(_vm.volumesOpen.includes(volumeToc.docId))?_vm._l((volumeToc.rootNodeKeys),function(tocKey){return _c('toc-v2-node',_vm._b({key:_vm.tocReferenceKey(volumeToc.docId, tocKey),attrs:{"doc-id":volumeToc.docId,"hide-page-seq":true,"focused-node":null},on:{"visitSection":function($event){return _vm.$emit('visitSection', $event)}}},'toc-v2-node',{ activeViewer: _vm.activeViewer, currentRecord: _vm.currentRecord, tocKey },false))}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }