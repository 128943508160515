<template>
  <li v-if="nodeInfo" :data-toc-node="referenceKey" class="toc-node" :class="{ active: nodeInfo.active }">
    <div ref="container" class="toc-container">
      <v-tooltip bottom :open-delay="300">
        <template #activator="{ on, attrs }">
          <a class="toc-content" :class="`depth-${nodeInfo.depth}`" v-bind="attrs" @click.stop="visitSection" v-on="on">
            <span class="text">{{ text }}</span>
            <wklr-icon-button
              v-if="!nodeInfo.isLeaf"
              type="chevron"
              :dense="true"
              :direction="open ? 'down' : 'right'"
              @click.stop="toggle"
            />
          </a>
        </template>
        <span>{{ text }}</span>
      </v-tooltip>

      <hit-counter v-if="nodeInfo.sectionHit" :section-hit="nodeInfo.sectionHit" :open="open" />
    </div>

    <ol v-if="!nodeInfo.isLeaf && open">
      <toc-node
        v-for="tocKey in nodeInfo.children"
        ref="children"
        :key="tocKey"
        v-bind="{
          ...$props,
          tocKey,
        }"
        @visitSection="$emit('visitSection', $event)"
      />
    </ol>
  </li>
</template>

<script lang="ts">
import { Component, Vue, Prop, State } from 'nuxt-property-decorator';
import { Viewer, TocInteractive } from '@/types/Viewer';
import { State as MyState, FindState } from '@/store';
import { Nullable } from '@/types/nullable';
import HitCounter from '@/components/toc-panel/hit-counter.vue';
import WklrIconButton from '@/components/shared/wklr-icon-btn.vue';
import { tocReferenceKey } from '@/utils/tocUtils';

@Component({
  name: 'toc-node',
  components: { HitCounter, WklrIconButton },
})
export default class TocNodeComponent extends Vue {
  $refs!: {
    container: HTMLDivElement;
    children: TocNodeComponent[];
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  @Prop() docId!: string;
  @Prop() tocKey!: number;
  @Prop() hidePageSeq!: boolean;
  @Prop() activeViewer!: Viewer;
  @Prop() focusedNode!: Nullable<number>;

  @State((state: MyState) => state.document.toc) toc!: Record<string, TocInteractive>;
  @State((state: MyState) => state.document.find) findState!: FindState;

  get referenceKey(): string {
    return tocReferenceKey(this.docId, this.tocKey);
  }

  get nodeInfo(): TocInteractive {
    return this.toc[this.referenceKey];
  }

  get open(): boolean {
    if (this.nodeInfo.isLeaf) {
      return true;
    }
    if (this.nodeInfo.open !== null) {
      return this.nodeInfo.open;
    }

    if (this.nodeInfo.active) return true;
    // // FIXME: accumulatedHitKeywordsCount をサーバ側で実装してそれに置き換え、子要素のレンダリングを止める（ L39 に isOpen の判定を追加する）
    if (this.nodeInfo.sectionHit !== null && this.nodeInfo.sectionHit.hitKeywordsCount >= 2) return true;
    return false;
  }

  get text(): string {
    return this.hidePageSeq ? this.nodeInfo.label : `${this.nodeInfo.label} ${this.nodeInfo.folioLabel}`;
  }

  visitSection(): void {
    this.$store.commit('toggleTocOpen', { key: this.referenceKey, isOpen: true });
    if (this.activeViewer === 'pdf' && this.nodeInfo.pdfPageScrollTo) {
      this.nodeInfo.pdfPageScrollTo();
    } else {
      this.$emit('visitSection', { docId: this.docId, key: this.tocKey });
    }
  }

  toggle(): void {
    this.$store.commit('toggleTocOpen', { key: this.referenceKey, isOpen: !this.nodeInfo.open });
  }
}
</script>

<style lang="scss" scoped>
.toc-node {
  width: 100%;
  background: $toc-background;
  list-style: none;

  > .toc-container {
    display: flex;
    align-items: stretch;
    width: 100%;
    background-color: $toc-background;

    > .toc-content {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      width: calc(100% - 32px);
      background: white;
      color: #333;
      transition: background-color 0.5s linear;
      border-left: 8px solid transparent;
      border-bottom: 1px solid $toc-divider-color;
      z-index: $layer-document-toc-content;

      &:hover {
        box-shadow: 0 0 8px gray;
        z-index: $layer-document-toc-content-hover;
      }

      &.depth-0 {
        background-color: #bad2ec;
        font-weight: bold;
      }

      &.depth-1 {
        background-color: #d4e3f5;
      }

      &.depth-2 {
        background-color: #edf4fc;
      }

      > .text {
        display: block;
        width: 100%;
        margin: 4px 0;
        padding: 0 16px 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .toggle {
        margin-left: auto;
      }
    }

    > .toc-content {
      @for $i from 0 through 9 {
        &.depth-#{$i} > .text {
          padding-left: 8px + (8px * $i);
        }
      }
    }
  }

  &.active > .toc-container > .toc-content {
    border-left-color: $toc-background-reading;
  }
}
</style>
