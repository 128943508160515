<template>
  <v-list dense class="pa-0">
    <v-list-item-group
      :value="displayedAttachment ? displayedAttachment.id : null"
      color="primary"
      :mandatory="!!displayedAttachment"
    >
      <template v-for="attachment in attachments">
        <v-list-item
          :key="`list-item-${attachment.id}`"
          two-line
          class="attachment"
          :value="attachment.id"
          @click="selectAttachment(attachment)"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-chip label x-small :color="attachment.color" :text-color="attachment.textColor">
                {{ attachment.label }}

                <v-icon v-if="attachment.downloadExtension !== undefined" right x-small> mdi-download </v-icon>

                <v-icon v-if="attachment.mime === EXTERNAL_LINK_ATTACHMENT_MIME" right x-small>
                  mdi-open-in-new
                </v-icon>
              </v-chip>
            </v-list-item-subtitle>
            <v-list-item-title class="mt-1">{{ attachment.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`divider-${attachment.id}`" />
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { downloadByURL } from '@/utility';
import { Attachment, AttachmentTiny, DocRecord } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';

const EXTERNAL_LINK_ATTACHMENT_MIME = 'text/vnd.legalscape.external-link';

interface AttachmentType {
  mime: string;
  label: string;
  color: string;
  textColor: string;
  downloadExtension?: string;
}

const attachmentTypes = [
  {
    mime: 'application/pdf',
    label: 'PDF',
    color: '#b30b00',
    textColor: 'white',
  },
  {
    mime: 'application/msword',
    label: 'Word',
    color: '#185abd',
    textColor: 'white',
    downloadExtension: 'doc',
  },
  {
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    label: 'Word',
    color: '#185abd',
    textColor: 'white',
    downloadExtension: 'docx',
  },
  {
    mime: 'application/vnd.ms-excel',
    label: 'Excel',
    color: '#107c41',
    textColor: 'white',
    downloadExtension: 'xls',
  },
  {
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    label: 'Excel',
    color: '#107c41',
    textColor: 'white',
    downloadExtension: 'xlsx',
  },
  {
    mime: 'application/rtf',
    label: 'RTF',
    color: '#00adc1',
    textColor: 'white',
    downloadExtension: 'rtf',
  },
  {
    mime: 'image/png',
    label: '画像',
    color: 'primary',
    textColor: 'white',
  },
  {
    mime: 'image/jpeg',
    label: '画像',
    color: 'primary',
    textColor: 'white',
  },
  {
    mime: 'text/vnd.legalscape.external-link',
    label: '外部リンク',
    color: 'default',
    textColor: 'default',
  },
];

const attachmentTypesMap: Record<string, AttachmentType> = Object.fromEntries(
  attachmentTypes.map((at) => [at.mime, at]),
);

@Component
export default class AttachmentList extends Vue {
  readonly EXTERNAL_LINK_ATTACHMENT_MIME = EXTERNAL_LINK_ATTACHMENT_MIME;

  @Prop() record!: DocRecord;

  @Prop() displayedAttachment!: Attachment | null;

  async selectAttachment({ id, name, downloadExtension }: AttachmentTiny & AttachmentType): Promise<void> {
    let downloadAs: string | undefined = undefined;
    if (downloadExtension !== undefined) {
      if (downloadExtension === '' || name.endsWith(downloadExtension)) {
        downloadAs = name;
      } else {
        downloadAs = `${name}.${downloadExtension}`;
      }
    }
    const attachment = await this.$repositories.attachments.getAttachment(this.record.id, id, downloadAs);
    if (attachment.mime === EXTERNAL_LINK_ATTACHMENT_MIME) {
      window.open(attachment.url, '_blank');
    } else if (downloadAs !== undefined) {
      downloadByURL(attachment.url);
    } else {
      this.$emit('openAttachment', attachment);
    }
  }

  get attachments(): (AttachmentTiny & AttachmentType)[] {
    if (!isAccessible(this.record)) return [];
    if (!('attachments' in this.record)) return [];
    return this.record.attachments.map((attachment) => {
      let attachmentType: AttachmentType = attachmentTypesMap[attachment.mime];
      if (!attachmentType) {
        console.warn(`attachment with unexpected mime; ${attachment.mime}`);
        attachmentType = {
          mime: 'unknown',
          label: 'その他',
          color: 'default',
          textColor: 'default',
          downloadExtension: '',
        };
      }
      return {
        ...attachment,
        ...attachmentType,
      };
    });
  }
}
</script>

<style lang="scss" scoped></style>
