import { ref } from 'vue';
export default defineComponent({
  setup: function setup() {
    var visible = ref(true);
    function closeNotice() {
      visible.value = false;
    }
    return {
      visible: visible,
      closeNotice: closeNotice
    };
  }
});