<template>
  <v-container class="error-viewer">
    <h1 class="heading">この文献は本文が存在しません</h1>
    <p class="paragraph">書誌情報のみの提供となります。</p>
    <p v-if="'attachments' in record && record.attachments.length > 0" class="paragraph">
      {{ record.attachments.length }} 個の添付ファイルが閲覧可能です。
    </p>
  </v-container>
</template>

<script lang="ts">
import { DocRecord } from 'wklr-backend-sdk/models';
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class NoneViewer extends Vue {
  @Prop() record!: DocRecord;
}
</script>

<style lang="scss" scoped>
.error-viewer {
  padding: 24px 36px;

  > .heading {
    margin-bottom: 24px;
  }

  > .paragraph {
    line-height: 1.6;
  }
}
</style>
