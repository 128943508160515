var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.nodeInfo)?_c('li',{staticClass:"toc-v2-node",class:{ active: _vm.nodeInfo.active, focused: _vm.isFocused },attrs:{"data-toc-node":_vm.referenceKey}},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false,"disabled":!_vm.showGlance,"open-delay":"350","z-index":"1101"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"toc-container",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.visitSection.apply(null, arguments)}}},'div',attrs,false),on),[_c('hit-counter-v2',_vm._b({},'hit-counter-v2',{ nodeInfo: _vm.nodeInfo, showAsHavingChildren: _vm.showAsHavingChildren, open: _vm.open },false)),_vm._v(" "),_c('div',{staticClass:"toc-content",class:{
            '-less-important': _vm.variant === 'kommentar' && _vm.nodeInfo.depth !== _vm.KommentarArticleDepth,
            '-no-hit': _vm.isFindStateActivated && !_vm.nodeInfo.sectionHit,
          }},[_c('a',{staticClass:"text",style:({ paddingLeft: _vm.paddingLeft }),attrs:{"href":_vm.href},domProps:{"textContent":_vm._s(_vm.text)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.visitSection.apply(null, arguments)}}}),_vm._v(" "),(_vm.showAsHavingChildren)?_c('wklr-icon-button',{attrs:{"type":"chevron","dense":true,"direction":_vm.open ? 'down' : 'right',"title":_vm.open ? '折りたたむ' : '展開する'},on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}}):(_vm.showGlance)?_c('v-icon',{staticStyle:{"opacity":"0.7"}},[_vm._v("mdi-menu-right")]):_vm._e()],1)],1)]}}],null,false,1096321168)},[_vm._v(" "),(_vm.showGlance)?_c('toc-glance',_vm._b({on:{"visitSection":function($event){return _vm.$emit('visitSection', $event)}}},'toc-glance',{ docId: _vm.docId, nodeInfo: _vm.nodeInfo },false)):_vm._e()],1),_vm._v(" "),(_vm.showAsHavingChildren && _vm.open)?_c('ol',_vm._l((_vm.nodeInfo.children),function(tocKey){return _c('toc-v2-node',_vm._b({key:tocKey,on:{"visitSection":function($event){return _vm.$emit('visitSection', $event)}}},'toc-v2-node',{
        ..._vm.$props,
        tocKey,
      },false))}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }